import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Skeleton } from "@material-ui/lab";

import { startAllActivities } from "../../../../redux/actions/activity";
import { ActivityCard } from "../card/ActivityCard";
import { RootState } from "../../../../redux/reducers/index";
import "./styles.scss";
import { ActivityCardLoading } from "../card/ActivityCardLoading";

export const ActivityList = () => {
  const dispatch = useDispatch();
  const { activities } = useSelector((state: RootState) => state.activity);
  const [loading, setLoading] = useState<boolean>(true);

  const loadActivities = useCallback(async () => {
    await dispatch(startAllActivities());
    setLoading(false);
    setScrollY()
  }, [dispatch, setLoading]);

  const setScrollY = () => {
    const porScrollY = localStorage.getItem("posScrollY")
    if (porScrollY) window.scrollTo(0, parseInt(porScrollY))
    localStorage.removeItem("posScrollY")
  }

  useEffect(() => {
    loadActivities();
  }, [loadActivities]);
  return (
    <Grid className="activity-list" container spacing={4}>
      {loading
        ? [1, 2, 3, 4, 5, 6, 7, 8].map((load: number) => (
            <Grid key={load} item xs={12} sm={6} lg={3}>
              <ActivityCardLoading />
            </Grid>
          ))
        : activities &&
          (activities.length ? (
            activities.map((activity: IActivity, i) => (
              <Grid key={activity.id} item xs={12} sm={6} lg={3}>
                <ActivityCard {...activity} />
              </Grid>
            ))
          ) : (
            <h3>Sin actividades</h3>
        ))}
    </Grid>
  );
};
