import Swal from "sweetalert2";
import { DeleteHotel } from "../../helpers/hotel/DeleteHotel";
import { GetHotelBySlug } from "../../helpers/hotel/GetHotelBySlug";
import { GetAllHotels } from "../../helpers/hotel/GetAllHotels";
import { PostSaveHotel } from "../../helpers/hotel/PostSaveHotel";
import { PutUpdateHotel } from "../../helpers/hotel/PutUpdateHotel";
import { types } from "../types";

export const hotels = (hotels: IHotel[]) => ({
  type: types.hotelsLoad,
  payload: { hotels },
});

export const hotel = (hotel: IHotel) => ({
  type: types.hotelLoad,
  payload: { hotel },
});

export const startAllHotels = () => {
  return async (dispatch: any) => {
    // Swal.fire({
    //   title: "Cargando..",
    //   allowOutsideClick: false,
    //   // html: 'I will close in <b></b> milliseconds.',
    //   // timer: 2000,
    //   // timerProgressBar: true,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    let allHotels = await GetAllHotels();
    await dispatch(hotels(allHotels));
    // Swal.hideLoading();
    // Swal.close();

    // dispatch(finishLoading());
  };
};

export const startHotelBySlug = (slug: string) => {
  return async (dispatch: any) => {
    // Swal.fire({
    //   title: "Cargando..",
    //   allowOutsideClick: false,
    //   // html: 'I will close in <b></b> milliseconds.',
    //   // timer: 2000,
    //   // timerProgressBar: true,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    let getHotel = await GetHotelBySlug(slug);
    await dispatch(hotel(getHotel));
    // Swal.hideLoading();
    // Swal.close();

    // dispatch(finishLoading());
  };
};

export const startUpserHotel = (
  hotel: IHotel,
  delActivities: number[],
  delImages: number[],
  delVideos: number[],
  delPrices: number[],
  newActivities: IHotelActivity[],
  newImages: IImages[],
  newVideos: IVideos[],
  newPrices: IPrices[],
  token: string
) => {
  return async (dispatch: any) => {
    Swal.fire({
      title: "Guardando..",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let data = {
      hotel,
      delActivities,
      delImages,
      delVideos,
      delPrices,
      newActivities,
      newImages,
      newVideos,
      newPrices,
    };

    let res = null;

    if (hotel.id) res = await PutUpdateHotel(data, token);
    else res = await PostSaveHotel(data, token);

    Swal.hideLoading();
    Swal.close();

    if (res.ok) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
      return null;
    }

    return `/admin/hoteles/show/${res.data.slug}`;
  };
};

export const startDeleteHotel = (id: number, token: string) => {
  return async (dispatch: any) =>
    await Swal.fire({
      title: "¿Está seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar actividad!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Eliminando..",
          allowOutsideClick: false,
          // html: 'I will close in <b></b> milliseconds.',
          // timer: 2000,
          // timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        let res = await DeleteHotel(id, token);

        Swal.hideLoading();
        Swal.close();

        if (res.ok) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
          return null;
        }

        return `/admin/hoteles`;
      }
    });
};
