import Swal from "sweetalert2";
import { DeleteActivity } from "../../helpers/activity/DeleteActivity";
import { GetActivityBySlug } from "../../helpers/activity/GetActivityBySlug";
import { GetAllActivities } from "../../helpers/activity/GetAllActivities";
import { PostSaveActivity } from "../../helpers/activity/PostSaveActivity";
import { PutUpdateActivity } from "../../helpers/activity/PutUpdateActivity";
import { types } from "../types";

export const activities = (activities: IActivity[]) => ({
  type: types.activitiesLoad,
  payload: { activities },
});

export const activity = (activity: IActivity) => ({
  type: types.activityLoad,
  payload: { activity },
});

export const startAllActivities = () => {
  return async (dispatch: any) => {
    // Swal.fire({
    //   title: "Cargando..",
    //   allowOutsideClick: false,
    //   // html: 'I will close in <b></b> milliseconds.',
    //   // timer: 2000,
    //   // timerProgressBar: true,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    let allActivities = await GetAllActivities();
    await dispatch(activities(allActivities));
    // Swal.hideLoading();
    // Swal.close();

    // dispatch(finishLoading());
  };
};

export const activityOrderByName = (activities_list: IActivity[]) => {
  return async (dispatch: any) => {
    activities_list.sort((a: IActivity, b: IActivity) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    await dispatch(activities(activities_list));
  };
};

export const startActivityBySlug = (slug: string) => {
  return async (dispatch: any) => {
    // Swal.fire({
    //   title: "Cargando..",
    //   allowOutsideClick: false,
    //   // html: 'I will close in <b></b> milliseconds.',
    //   // timer: 2000,
    //   // timerProgressBar: true,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    let getActivity = await GetActivityBySlug(slug);
    await dispatch(activity(getActivity));
    // Swal.hideLoading();
    // Swal.close();

    // dispatch(finishLoading());
  };
};

export const startUpserActivity = (
  activity: IActivity,
  delImages: number[],
  delVideos: number[],
  delPrices: number[],
  newImages: IImages[],
  newVideos: IVideos[],
  newPrices: IPrices[],
  token: string
) => {
  return async (dispatch: any) => {
    Swal.fire({
      title: "Guardando..",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });    

    let data = {
      activity,
      delImages,
      delVideos,
      delPrices,
      newImages,
      newVideos,
      newPrices,
    };    

    let res = null;

    if (activity.id) res = await PutUpdateActivity(data, token);
    else res = await PostSaveActivity(data, token);

    Swal.hideLoading();
    Swal.close();    

    if (res.ok) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
      return null;
    }

    return `/admin/actividades/show/${res.data.slug}`;
  };
};

export const startDeleteActivity = (id: number, token: string) => {
  return async (dispatch: any) =>
    await Swal.fire({
      title: "¿Está seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar actividad!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Eliminando..",
          allowOutsideClick: false,
          // html: 'I will close in <b></b> milliseconds.',
          // timer: 2000,
          // timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        let res = await DeleteActivity(id, token);

        Swal.hideLoading();
        Swal.close();

        if (res.ok) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
          return null;
        }

        return `/admin/actividades`;
      }
    });
};
