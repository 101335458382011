import { HotelList } from "./components/list/HotelList";
import "./styles.scss"

export const HotelView = () => {
  return (
    <div className="hotel-container">
      <h2>Hoteles turísticos</h2>
      <HotelList />
    </div>
  );
};
