import { types } from "../types";
// import { UserI } from "../../views/auth/login/LoginI";

export const initialStateTopic: TopicState = {
  topics: [],
  topic: {
    id: 0,
    name: "",
    slug: "",
  },
};

export const topicReducer = (
  state: TopicState = initialStateTopic,
  action: TopicAction
): TopicState => {
  switch (action.type) {
    case types.topicsLoad:
      return {
        ...state,
        topics: action.payload.topics,
      };

    case types.topicLoad:
      return {
        ...state,
        topic: action.payload.topic,
      };

    default:
      return { ...state };
  }
};
