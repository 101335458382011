import { API } from "../../api";

interface ISavetPackage {
  package: IPackage;
  delImages: number[];
  delVideos: number[];
  delPrices: number[];
  newImages: IImages[];
  newVideos: IVideos[];
  newPrices: IPrices[];
}

export const PostSavePackage = async (data: ISavetPackage, token: string) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await API.POST(`/package`, data, config).then((data: IData) => data);
};
