import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { startPackageBySlug } from "../../../../redux/actions/package";
import { PackageForm } from "../form/PackageForm";
import { RootState } from "../../../../redux/reducers/index";
import { PreloadIcon } from "../../../../components/common/preload/PreloadView";
import { startAllHotels } from "../../../../redux/actions/hotel";
import { startAllActivities } from "../../../../redux/actions/activity";

// import { RootState } from "../../../../redux/reducers/index";

export const PackageEdit = () => {
  const dispatch = useDispatch();
  const { slug }: any = useParams();

  const { package: packag } = useSelector((state: RootState) => state.package);
  const [loaidng, setLoading] = useState<boolean>(true);

  const { hotels: hotels_list } = useSelector(
    (state: RootState) => state.hotel
  );

  const { activities: activities_list } = useSelector(
    (state: RootState) => state.activity
  );

  const loadPackage = useCallback(async () => {
    await dispatch(startPackageBySlug(slug));
  }, [dispatch, slug]);

  const loadData = useCallback(async () => {
    await dispatch(startAllHotels());
    await dispatch(startAllActivities());
    await loadPackage();
    setLoading(false);
  }, [dispatch, loadPackage]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loaidng) {
    return <PreloadIcon />;
  }

  if (!packag.slug) {
    return (
      <div className="package-edit-container">
        <h3>Paquete no encontrado</h3>
      </div>
    );
  }

  return (
    <div className="package-edit-container">
      <PackageForm
        packag={{...packag}}
        hotels_list={hotels_list}
        activities_list={activities_list}
      />
    </div>
  );
};
