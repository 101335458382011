import {
  // Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik, Form, FieldArray } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { startUpserHotel } from "../../../../redux/actions/hotel";
import { startAllHotelCategories } from "../../../../redux/actions/hotelCategory";

import { RootState } from "../../../../redux/reducers/index";
import { HotelFormImageList } from "./HotelFormImageList";
import { HotelFormPriceList } from "./HotelFormPriceList";
import { HotelFormActivityList } from "./HotelFormActivityList";

export const HotelForm = (hotel: IHotel) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { hotelCategories } = useSelector(
    (state: RootState) => state.hotelCategory
  );
  const { token } = useSelector((state: RootState) => state.auth);

  const [redirect, setRedirect] = useState<string | null>("");

  const [activities, setActivities] = useState<IHotelActivity[]>([]);
  const [images, setImages] = useState<IImages[]>([]);
  const [videos, setVideos] = useState<IVideos[]>([]);
  const [prices, setPrices] = useState<IPrices[]>([]);

  const [delActivities, setDelActivities] = useState<number[]>([]);
  const [delImages, setDelImages] = useState<number[]>([]);
  const [delVideos, setDelVideos] = useState<number[]>([]);
  const [delPrices, setDelPrices] = useState<number[]>([]);

  const loadTopic = useCallback(async () => {
    await dispatch(startAllHotelCategories());
  }, [dispatch]);

  hotel.activities.forEach((h: any) => {
    h.delImages = [];
    h.delVideos = [];
    h.delPrices = [];
    h.newImages = [];
    h.newVideos = [];
    h.newPrices = [];
  });

  useEffect(() => {
    loadTopic()
  }, [loadTopic]);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <div className="hotel-edit-container">
      <Formik
        initialValues={hotel}
        validate={(values) => {
          let errors: any = {};
          // Email
          if (!values.name) errors.name = "Nombre del hotel requerido";
          if (!values.hotelCategory_id)
            errors.hotelCategory_id = "El tipo de hotel requerido";
          if (!prices.length && !values.prices.length)
            errors.prices = "Almenos debes ingresar un precio";
          if (!images.length && !values.images.length)
            errors.images = "Almenos debes ingresar una imagen";
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          // console.log(values, " Loque envia");
          // console.log(activities, " Loque envia 1");
          let res = await dispatch(
            startUpserHotel(
              values,
              delActivities,
              delImages,
              delVideos,
              delPrices,
              activities,
              images,
              videos,
              prices,
              token
            )
          );
          if (res) setRedirect(res.toString());
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} id="form-hotel">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="name"
                  label="Nombre del hotel *"
                  type="text"
                  name="name"
                  autoComplete="off"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && touched.name && (
                  <Alert severity="error">{errors.name}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Categoria del hotel *
                  </InputLabel>
                  <Select
                    name="hotelCategory_id"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={values.hotelCategory_id}
                    defaultValue={0}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>
                      <em>Seleccinar</em>
                    </MenuItem>
                    {hotelCategories.map((hotelCategory, i) => (
                      <MenuItem key={hotelCategory.id} value={hotelCategory.id}>
                        {hotelCategory.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.hotelCategory_id && touched.hotelCategory_id && (
                  <Alert severity="error">{errors.hotelCategory_id}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="description"
                  label="Descripción"
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.description && touched.description && (
                  <Alert severity="error">{errors.description}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="include"
                  label="Incluye"
                  type="text"
                  name="include"
                  value={values.include}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.include && touched.include && (
                  <Alert severity="error">{errors.include}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="note"
                  label="Nota"
                  type="text"
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.note && touched.note && (
                  <Alert severity="error">{errors.note}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Precios *
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="prices"
                    render={(arrayHelpers) => (
                      <HotelFormPriceList
                        priceDelete={(index: number) => {
                          setDelPrices([...delPrices, values.prices[index].id]);
                          arrayHelpers.remove(index);
                        }}
                        pricesUpdate={(index: number, newPrices: any) =>
                          // setPrices([...newPrices])
                          arrayHelpers.replace(index, newPrices)
                        }
                        prices={values.prices}
                        errors={errors}
                        type="price"
                      />
                    )}
                  />
                  <HotelFormPriceList
                    pricesUpdate={(newPrices: any) => setPrices([...newPrices])}
                    prices={prices}
                    errors={errors}
                    type="newPrices"
                  />
                </Grid>
                {errors.prices && touched.prices && (
                  <Alert severity="error">{errors.prices}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Imagenes *
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="images"
                    render={(arrayHelpers) => (
                      <HotelFormImageList
                        filesUpdate={(index: number) => {
                          setDelImages([...delImages, values.images[index].id]);
                          arrayHelpers.remove(index);
                        }}
                        files={values.images}
                        type="image"
                      />
                    )}
                  />
                  <HotelFormImageList
                    filesUpdate={(newFiles: any) => setImages([...newFiles])}
                    files={images}
                    type="newImages"
                  />
                </Grid>
                {errors.images && touched.images && (
                  <Alert severity="error">{errors.images}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Videos
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="videos"
                    render={(arrayHelpers) => (
                      <HotelFormImageList
                        filesUpdate={(index: number) => {
                          setDelVideos([...delVideos, values.videos[index].id]);
                          arrayHelpers.remove(index);
                        }}
                        files={values.videos}
                        type="video"
                      />
                    )}
                  />

                  <HotelFormImageList
                    filesUpdate={(newFiles: any) => setVideos([...newFiles])}
                    files={videos}
                    type="newVideos"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Actividades
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="activities"
                    render={(arrayHelpers) => (
                      <HotelFormActivityList
                        activityDelete={(index: number) => {
                          setDelActivities([
                            ...delActivities,
                            values.activities[index].activity.id,
                          ]);
                          arrayHelpers.remove(index);
                        }}
                        activitiesUpdate={(index: number, newActivities: any) =>
                          // setActivities([...newActivities])
                          arrayHelpers.replace(index, newActivities)
                        }
                        activities={values.activities}
                        errors={errors}
                        type="activity"
                      />
                    )}
                  />
                  <HotelFormActivityList
                    activitiesUpdate={(newActivities: any) =>
                      setActivities([...newActivities])
                    }
                    activities={activities}
                    errors={errors}
                    type="newActivities"
                  />
                </Grid>
                {errors.activities && touched.activities && (
                  <Alert severity="error">{errors.activities}</Alert>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  })
);
