import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { HotelList } from "../../../hotel/components/list/HotelList";
import "./styles.scss";
import "../styles.scss";

export const HotelView = () => {

  return (
    <>
      <Grid container className="admin-container-header">
        <Grid item xs={6}>
          <h2>Hoteles</h2>
        </Grid>
        <Grid item xs={6} className="admin-container-header-buttons">
          <Link to={`/admin/hoteles/create`}>
            <Button variant="contained" color="primary">
              Nuevo hotel
            </Button>
          </Link>
        </Grid>
      </Grid>
      <hr />
      <div className="admin-container-body">
        <HotelList />
      </div>
    </>
  );
};
