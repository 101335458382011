import { Button, Grid } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { startDeletePackage } from "../../../../../redux/actions/package";
import { RootState } from "../../../../../redux/reducers";
import { PackageEdit } from "../../../../package/components/edit/PackageEdit";
import "./styles.scss";

export const PackageAdminEdit = () => {
  const dispatch = useDispatch();
  const { package: packag } = useSelector((state: RootState) => state.package);
  const { token } = useSelector((state: RootState) => state.auth);
  const [redirect, setRedirect] = useState<string | null>("");
  const history = useHistory();

  const handleDelete = async () => {
    let res = await dispatch(startDeletePackage(packag.id, token));
    if (res) setRedirect(res.toString());
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      <Grid container spacing={1} className="admin-container-header">
        <Grid item xs={12} sm={6}>
          <h2>Actualizando paquete</h2>
        </Grid>
        {packag.slug && (
          <Grid item xs={12} sm={6} className="admin-container-header-buttons">
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              Eliminar
            </Button>
            <Button
              type="button"
              variant="contained"
              color="default"
              onClick={()=>history.goBack()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              form="form-package"
              variant="contained"
              color="primary"
            >
              Guardar
            </Button>
          </Grid>
        )}
      </Grid>
      <hr />
      <div className="admin-container-body">
        <PackageEdit />
      </div>
    </>
  );
};
