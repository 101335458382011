// import { Button } from "@material-ui/core";
// import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

// import { URL_IMAGES } from "../../../../api";
// import img from "../../../../assets/images/banner.jpg";

import "./styles.scss";

export const ActivityCardLoading = () => {
  return (
    <div className="activivy-card">
      {/* <figure> */}
      <Skeleton animation="wave" style={{ height: 300, width: "100%" }} />
      {/* <img
          src={`${URL_IMAGES}${images[0].image}`}
          alt=""
          height="300"
          width="100%"
        /> */}
      {/* </figure> */}
      <div className="activity-card-detail">
        <Skeleton
          animation="wave"
          style={{ height: 20, width: "40%", margin: 0, padding: 0 }}
        />
        <Skeleton
          animation="wave"
          style={{ height: 20, width: "90%", margin: 0, padding: 0 }}
        />
        <Skeleton
          animation="wave"
          style={{ height: 40, width: "30%", margin: 0, padding: 0 }}
        />
        {/* <h3>{name}</h3>
        <p>{description}</p> */}
        {/* <Link to={`/admin/actividades/show/${slug}`}>
          <Button
            variant="outlined"
            color="primary"
            className="activity-btn-detail"
          >
            Ver detalles
          </Button>
        </Link> */}
      </div>
    </div>
  );
};
