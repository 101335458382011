import { API } from "../../api";

export const DeletePackage = async (id: number, token: string) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await API.DEL(`/package/${id}`, config).then((data: IData) => data);
};
