import { PackageList } from './components/list/PackageList';
import "./styles.scss"

export const PackageView = () => {
    return (
        <div className="package-container">
            <h2>Paquetes turísticos</h2>
            <PackageList/>
        </div>
    )
}
