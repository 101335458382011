import { Switch, Route, Redirect } from "react-router-dom";
import { HeaderAdmin } from "../components/ui/layouts/admin/HeaderAdmin";
import { HomeView } from "../views/admin/views/home/HomeView";
// activities
import { ActivityView } from "../views/admin/views/activity/ActivityView";
import { ActivityAdminCreate } from "../views/admin/views/activity/components/ActivityAdminCreate";
import { ActivityAdminEdit } from "../views/admin/views/activity/components/ActivityAdminEdit";
import { ActivityAdminShow } from "../views/admin/views/activity/components/ActivityAdminShow";
// hotels
import { HotelView } from "../views/admin/views/hotel/HotelView";
import { HotelAdminCreate } from "../views/admin/views/hotel/components/HotelAdminCreate";
import { HotelAdminEdit } from "../views/admin/views/hotel/components/HotelAdminEdit";
import { HotelAdminShow } from "../views/admin/views/hotel/components/HotelAdminShow";
// packages
import { PackageView } from "../views/admin/views/package/PackageView";
import { PackageAdminCreate } from "../views/admin/views/package/components/PackageAdminCreate";
import { PackageAdminEdit } from "../views/admin/views/package/components/PackageAdminEdit";
import { PackageAdminShow } from "../views/admin/views/package/components/PackageAdminShow";
// contacts
import { ContactView } from "../views/admin/views/contact/ContactView";
import { ContactShow } from "../views/admin/views/contact/components/show/ContactShow";

export const AdminRouter = () => {
  return (
    <HeaderAdmin>
      <div className="admin-container">
        <Switch>
          <Route exact path="/admin" component={HomeView} />

          {/* HOTELS */}
          <Route exact path="/admin/hoteles" component={HotelView} />
          <Route
            exact
            path="/admin/hoteles/create"
            component={HotelAdminCreate}
          />
          <Route
            exact
            path="/admin/hoteles/show/:slug"
            component={HotelAdminShow}
          />
          <Route
            exact
            path="/admin/hoteles/edit/:slug"
            component={HotelAdminEdit}
          />

          {/* PAQUETES */}
          <Route exact path="/admin/paquetes" component={PackageView} />
          <Route
            exact
            path="/admin/paquetes/create"
            component={PackageAdminCreate}
          />
          <Route
            exact
            path="/admin/paquetes/show/:slug"
            component={PackageAdminShow}
          />
          <Route
            exact
            path="/admin/paquetes/edit/:slug"
            component={PackageAdminEdit}
          />

          {/* ACTIVIDADES */}
          <Route exact path="/admin/actividades" component={ActivityView} />
          <Route
            exact
            path="/admin/actividades/create"
            component={ActivityAdminCreate}
          />
          <Route
            exact
            path="/admin/actividades/show/:slug"
            component={ActivityAdminShow}
          />
          <Route
            exact
            path="/admin/actividades/edit/:slug"
            component={ActivityAdminEdit}
          />

          {/* CONTACTS */}
          <Route exact path="/admin/contactos" component={ContactView} />
          <Route exact path="/admin/contactos/:id" component={ContactShow} />

          <Redirect to="/admin" />
        </Switch>
      </div>
    </HeaderAdmin>
  );
};
