import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import {
  startLoginEmailPassword,
  startGoogleLogin,
} from "../../../redux/actions/auth";
import { RootState } from "../../../redux/reducers/index";
import "../styles.scss";
import { Form, Formik } from "formik";

export const LoginView = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.ui);

  // const { email, password, handleInputChange } = useForm<IUser>({
  //   email: "info@nuquitours.com.co",
  //   password: "nuquitours123",
  // });

  const handleLogin = ({ email, password }: ICredentialsUser) => {
    dispatch(startLoginEmailPassword(email, password));
  };

  const handleGoogleLogin = () => {
    dispatch(startGoogleLogin());
  };

  window.scrollTo(0, 0);

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h3 className="auth__title">Iniciar sessión</h3>
        <br />
        <Formik
          initialValues={{
            email:"", //"info@nuquitours.com.co"
            password:"" //"nuquitours123"
          }}
          validate={(values) => {
            const errors: any = {};
            // Email
            if (!values.email) errors.email = "Correo equerido";
            else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            )
              errors.email = "Correo invalido";
            // Password
            if (!values.password) errors.password = "Contraseña Requerida";
            // else if(values.password?.length < 8)
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleLogin(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                id="email"
                label="Email"
                type="text"
                name="email"
                autoComplete="off"
                value={values.email}
                onChange={handleChange}
              />
              {errors.email && touched.email && (
                <Alert severity="error">{errors.email}</Alert>
              )}
              <TextField
                id="password"
                label="Contraseña"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
              />
              {errors.password && touched.password && (
                <Alert severity="error">{errors.password}</Alert>
              )}
              <Button
                type="submit"
                variant="contained"
                color="default"
                disabled={loading}
              >
                Ingresar
              </Button>
              <div className="auth__social-networks">
                <p>Ingresar con una red social</p>
                <br />
                <div className="google-btn" onClick={handleGoogleLogin}>
                  <div className="google-icon-wrapper">
                    <img
                      className="google-icon"
                      src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                      alt="google button"
                    />
                  </div>
                  <p className="btn-text">
                    <b>Ingresar con google</b>
                  </p>
                </div>
              </div>
              <br />
              <Link to="/register" className="link">
                Crear nueva cuenta
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
