import { API } from "../../api";

interface ISavetActivity {
  activity: IActivity;
  delImages: number[];
  delVideos: number[];
  delPrices: number[];
  newImages: IImages[];
  newVideos: IVideos[];
  newPrices: IPrices[];
}

export const PostSaveActivity = async (data: ISavetActivity, token: string) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await API.POST(`/activity`, data, config).then((data: IData) => data);
};
