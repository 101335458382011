import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { URL_IMAGES } from "../../../../api";
import { Add } from "@material-ui/icons";

import "./styles.scss";

export const DragAndDrop = (props) => {
  const classes = useStyles();

  let { name, images, action } = props;

  return (
    <div className="package__drag-drop">
      <figure>
        <img
          className={classes.img}
          src={`${URL_IMAGES}${images[0]?.url}`}
          alt=""
          height="150"
          width="100%"
        />
      </figure>
      <div className={classes.package__body}>
        <h3>{name}</h3>
        <div className={classes.package__body_button}>
          <Tooltip title="Añadir al paquete">
            <Button
              variant="outlined"
              color="primary"
              className="hotel-btn-detail"
              onClick={action}
            >
              <Add />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  img: {
    objectFit: "cover",
  },
  package__body: {
    padding: 10,
  },
  package__body_button: {
    margin: 5,
    display: "flex",
    justifyContent: "center",
  },
}));
