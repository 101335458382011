import {
  Card,
  CardContent,
  Fab,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { AttachMoney } from "@material-ui/icons";
import { HotelFormPriceCard } from "./HotelFormPriceCard";

export const HotelFormPriceList = ({
  prices,
  pricesUpdate,
  priceDelete,
  errors,
  type,
}: any) => {
  const classes = useStyles();
  const pricesAdd = () => {
    prices.push({
      id: prices.length,
      name: "",
      value: "",
    });
    pricesUpdate(prices);
  };

  const pricesDel = (delPrices: IPrices) => {
    if (type === "newPrices") {
      prices.splice(prices.indexOf(delPrices), 1);
      pricesUpdate(prices);
    } else {
      priceDelete(prices.indexOf(delPrices));
    }
  };

  const handleChagePrice = ({ value, name }: any, index: number) => {
    if (type === "newPrices") {
      prices[index][name] = value;
      pricesUpdate(prices);
    } else {
      prices[index][name] = value;
      pricesUpdate(index, prices[index]);
    }
  };

  return (
    <>
      {prices.map((price: IPrices, i: any) => (
        <HotelFormPriceCard
          key={price.id + i}
          pricesDel={pricesDel}
          price={price}
          i={i}
          errors={errors}
          handleChagePrice={(target: any) => handleChagePrice(target, i)}
        />
      ))}
      {type === "newPrices" && (
        <Grid item xs={12} sm={3}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Fab onClick={pricesAdd} color="secondary" aria-label="add">
                  <AttachMoney />
              </Fab>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: "flex",
    justifyContent: "center",
  },
}));
