import { Button, Grid, TextField } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

export const PackageFormPriceCard = ({
  pricesDel,
  price,
  errors,
  handleChagePrice,
  i,
}: any) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={3}>
      <Card>
        <CardHeader
          subheader={`${i + 1}`}
          action={
            <Button onClick={() => pricesDel(price)}>
              <Close />
            </Button>
          }
        />
        <CardContent>
            <TextField
              className={classes.formControl}
              id="name"
              label="Descripción"
              type="text"
              name="name"
              autoComplete="off"
              value={price.name}
              onChange={({ target }) => handleChagePrice(target)}
            />
            {errors.namePrice && (
              <Alert severity="error">{errors.namePrice}</Alert>
            )}
            <TextField
              className={classes.formControl}
              id="value"
              label="Precio"
              type="number"
              name="value"
              autoComplete="off"
              value={price.value}
              onChange={({ target }) => handleChagePrice(target)}
            />
            {errors.valuePrice && (
              <Alert severity="error">{errors.valuePrice}</Alert>
            )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  })
);
