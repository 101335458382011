import "./styles.scss";

interface itemCard {
  title: string;
  message: string;
  icon: any;
}

export const ContactItemCard = ({ title, message, icon }: itemCard) => {
  return (
    <div className="contact-item-card">
      {icon}
      <h4>{title}</h4>
      <p>{message}</p>
    </div>
  );
};
