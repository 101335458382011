import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { startActivityBySlug } from "../../../../redux/actions/activity";
import { ActivityForm } from "../form/ActivityForm";
import { RootState } from "../../../../redux/reducers/index";
import { PreloadIcon } from "../../../../components/common/preload/PreloadView";

// import { RootState } from "../../../../redux/reducers/index";

export const ActivityEdit = () => {
  const dispatch = useDispatch();
  const { slug }: any = useParams();

  const { activity } = useSelector((state: RootState) => state.activity);
  const [loaidng, setLoading] = useState<boolean>(true);

  const loadActivity = useCallback(async () => {
    await dispatch(startActivityBySlug(slug));
    setLoading(false);
  }, [dispatch, slug]);

  useEffect(() => {
    loadActivity();
  }, [loadActivity]);

  if (loaidng) {
    return <PreloadIcon />;
  }

  if (!activity.slug) {
    return (
      <div className="activity-edit-container">
        <h3>Actividad no encontrada</h3>
      </div>
    );
  }

  return (
    <div className="activity-edit-container">
      <ActivityForm {...activity} />
    </div>
  );
};
