import {
  Button,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Menu as MenuBar } from "@material-ui/icons";
import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";

import { NavBarAdmin } from "./NavBarAdmin";
import logo from "../../../../assets/images/logo.png";
import "./styles.scss";
import { NavBarAdminList } from "./components/NavBarAdminList";
import { AvatarProfile } from "./components/AvatarProfile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      flex: "1 0 auto",
      margin: theme.spacing(1),
    },
    list: {
      width: 250,
      height: "100%",
      backgroundColor: "#282C34",
    },
    fullList: {
      width: "auto",
    },
  })
);

export const HeaderAdmin = ({ children }: Element) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const classes = useStyles();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <NavBarAdminList />
    </div>
  );

  type Anchor = "top" | "left" | "bottom" | "right";
  return (
    <div className="header-admin">
      <NavBarAdmin />
      <div className="header-admin-container">
        <Hidden smDown>
          <header>
            <div className="header-admin-photo">
              <AvatarProfile />
            </div>
          </header>
        </Hidden>
        <Hidden mdUp>
          <nav className="navbar-admin-container-md">
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={toggleDrawer("left", true)}
            >
              <MenuBar />
            </Button>
            <SwipeableDrawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawer("left", false)}
              onOpen={toggleDrawer("left", true)}
            >
              {list("left")}
            </SwipeableDrawer>
            <img src={logo} alt="" height="30" />
            <AvatarProfile />
          </nav>
        </Hidden>
        <Hidden mdUp>
          <div className="layout-admin-container">{children}</div>
        </Hidden>
        <Hidden smDown>
          <div className="layout-admin-container-md">{children}</div>
        </Hidden>
      </div>
    </div>
  );
};
