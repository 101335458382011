import {
  // Button,
  createStyles,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik, Form, FieldArray } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  // package as packageAction,
  startUpserPackage,
} from "../../../../redux/actions/package";

import { RootState } from "../../../../redux/reducers/index";
import { DragAndDrop } from "../card/DragAnddDrop";
import { DragAndDropMini } from "../card/DragAnddDropMini";
import { PackageFormImageList } from "./PackageFormImageList";
import { PackageFormPriceList } from "./PackageFormPriceList";

export const PackageForm = (props: any) => {
  
  let { packag, hotels_list, activities_list } = props;
  
  const dispatch = useDispatch();
  const classes = useStyles();
  const { token } = useSelector((state: RootState) => state.auth);

  const [redirect, setRedirect] = useState<string | null>("");
  const [images, setImages] = useState<IImages[]>([]);
  const [videos, setVideos] = useState<IVideos[]>([]);
  const [prices, setPrices] = useState<IPrices[]>([]);

  const [delImages, setDelImages] = useState<number[]>([]);
  const [delVideos, setDelVideos] = useState<number[]>([]);
  const [delPrices, setDelPrices] = useState<number[]>([]);
  

  const [hotels, setHotels] = useState<IHotel[]>([]);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [hotelsList, setHotelsList] = useState<IHotel[]>(hotels_list);
  const [activityList, setActivityList] = useState<IActivity[]>(activities_list);

  const orderByName = () => {
    hotelsList.sort((a: IHotel, b: IHotel) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    activityList.sort((a: IActivity, b: IActivity) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    setHotelsList([...hotelsList]);
    setActivityList([...activityList]);
  };

  const loadHotel = () => {
    packag.hotels.forEach((hotel: IHotel) => {
      newHotel_id(hotel);
    });
  };
  const loadActivity = () => {
    packag.activities.forEach((activity: IActivity) => {
      newActivity_id(activity);
    });
  };

  const newHotel_id = (hotel: IHotel) => {
    hotels.push(hotel);
    setHotels([...hotels]);
    hotelsList.splice(hotelsList.indexOf(hotel), 1);
    setHotelsList([...hotelsList]);
  };

  const newActivity_id = (activity: IActivity) => {    
    activities.push(activity);
    setActivities([...activities]);
    const deleteActivity = activityList.find(x => x.id === activity.id);
    if (deleteActivity) {
      activityList.splice(activityList.indexOf(deleteActivity), 1);
      setActivityList([...activityList]);
    }
  };

  const delHotel_id = (h: IHotel) => {
    hotels.splice(hotels.indexOf(h), 1);
    setHotels([...hotels]);
    hotelsList.push(h);
    setHotelsList([...hotelsList]);
    orderByName();
  };

  const delActivity_id = (activity: IActivity) => {
    activities.splice(activities.indexOf(activity), 1);
    setActivities([...activities]);
    activityList.push(activity);
    setActivityList([...activityList]);
    orderByName();
  };

  useEffect(() => {
    loadHotel();
    loadActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="package-edit-container">
      <Formik
        initialValues={packag}
        validate={(values) => {
          let errors: any = {};
          // Email
          if (!values.name) errors.name = "Nombre del paquete requerido";
          if (!prices.length && !values.prices.length)
            errors.prices = "Almenos debes ingresar un precio";
          if (!images.length && !values.images.length)
            errors.images = "Almenos debes ingresar una imagen";
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          // handleLogin(values);
          let res = await dispatch(
            startUpserPackage(
              values,
              delImages,
              delVideos,
              delPrices,
              images,
              videos,
              prices,
              token,
              hotels,
              activities
            )
          );
          if (res) setRedirect(res.toString());
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} id="form-package">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="name"
                  label="Nombre del paquete *"
                  type="text"
                  name="name"
                  autoComplete="off"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && touched.name && (
                  <Alert severity="error">{errors.name}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="description"
                  label="Descripción"
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.description && touched.description && (
                  <Alert severity="error">{errors.description}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="include"
                  label="Incluye"
                  type="text"
                  name="include"
                  value={values.include}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.include && touched.include && (
                  <Alert severity="error">{errors.include}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="note"
                  label="Nota"
                  type="text"
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.note && touched.note && (
                  <Alert severity="error">{errors.note}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Precios *
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="prices"
                    render={(arrayHelpers) => (
                      <PackageFormPriceList
                        priceDelete={(index: number) => {
                          setDelPrices([...delPrices, values.prices[index].id]);
                          arrayHelpers.remove(index);
                        }}
                        pricesUpdate={(index: number, newPrices: any) =>
                          // setPrices([...newPrices])
                          arrayHelpers.replace(index, newPrices)
                        }
                        prices={values.prices}
                        errors={errors}
                        type="price"
                      />
                    )}
                  />
                  <PackageFormPriceList
                    pricesUpdate={(newPrices: any) => setPrices([...newPrices])}
                    prices={prices}
                    errors={errors}
                    type="newPrices"
                  />
                </Grid>
                {errors.prices && touched.prices && (
                  <Alert severity="error">{errors.prices}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Imagenes *
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="images"
                    render={(arrayHelpers) => (
                      <PackageFormImageList
                        filesUpdate={(index: number) => {
                          setDelImages([...delImages, values.images[index].id]);
                          arrayHelpers.remove(index);
                        }}
                        files={values.images}
                        type="image"
                      />
                    )}
                  />
                  <PackageFormImageList
                    filesUpdate={(newFiles: any) => setImages([...newFiles])}
                    files={images}
                    type="newImages"
                  />
                </Grid>
                {errors.images && touched.images && (
                  <Alert severity="error">{errors.images}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Videos
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="videos"
                    render={(arrayHelpers) => (
                      <PackageFormImageList
                        filesUpdate={(index: number) => {
                          setDelVideos([...delVideos, values.videos[index].id]);
                          arrayHelpers.remove(index);
                        }}
                        files={values.videos}
                        type="video"
                      />
                    )}
                  />

                  <PackageFormImageList
                    filesUpdate={(newFiles: any) => setVideos([...newFiles])}
                    files={videos}
                    type="newVideos"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <hr />
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                className={classes.package__hotel_activite}
              >
                <h3>Hoteles</h3>
                <Grid item xs={12} sm={5} className={classes.package__list}>
                  {hotelsList.map((h, i) => (
                    <DragAndDrop
                      key={i}
                      name={h.name}
                      images={h.images}
                      action={() => newHotel_id(h)}
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} className={classes.package}>
                <h2 className={classes.package_title}>Paquete</h2>
                <h2>Hotel</h2>
                <hr />
                <br />
                {hotels.map((h, i) => (
                  <DragAndDropMini
                    key={i}
                    name={h.name}
                    images={h.images}
                    action={() => delHotel_id(h)}
                  />
                ))}
                <br />
                <h2>Actividades</h2>
                <hr />
                <br />
                {activities.map((a, i) => (
                  <DragAndDropMini
                    key={i}
                    name={a.name}
                    images={a.images}
                    action={() => delActivity_id(a)}
                  />
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                className={classes.package__hotel_activite}
              >
                <h3>Actividades</h3>
                <Grid item xs={12} sm={5} className={classes.package__list}>
                  {activityList.map((a, i) => (
                    <DragAndDrop
                      key={i}
                      name={a.name}
                      images={a.images}
                      action={() => newActivity_id(a)}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    package_title: {
      textAlign: "center",
      marginBottom: 10,
    },
    package__hotel_activite: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      height: 800,
      overflow: "hidden",
      overflowY: "scroll",
    },
    package: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#a7f8a999",
      height: 800,
      overflow: "hidden",
      overflowY: "scroll",
    },
    package__list: {
      height: 800,
    },
  })
);
