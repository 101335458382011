import { Grid, Link } from "@material-ui/core";
import { Facebook } from "@material-ui/icons";

export const Footer = () => {
  return (
    <Grid container>
      <Grid item className="footer-background">
        <Grid container className="footer">
          <Grid item xs={12} md={12}>
            <ul>
              <li>Nuquí - Chocó - Colombia</li>
              <li>info@nuquitours.com.co</li>
              <li>+57 3185861747 - +57 3162946829</li>
              <li>Nuquí Tours</li>
            </ul>
          </Grid>
          <Grid item xs={12} md={12} className="footer-copyright">
            <p>© 2021 Nuquí Tours. Todos los derechos reservados.</p>
            <Link
              href="https://www.facebook.com/nuquitours/"
              target={"_blank"}
              color="inherit"
              variant="button"
            >
              <Facebook />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
