import { types } from "../types";
// import { UserI } from "../../views/auth/login/LoginI";

export const initialStateHotelCategory: HotelCategoryState = {
  hotelCategories: [],
  hotelCategory: {
    id: 0,
    name: "",
    slug: "",
    state_id: 0
  },
};

export const hotelCategoryReducer = (
  state: HotelCategoryState = initialStateHotelCategory,
  action: HotelCategoryAction
): HotelCategoryState => {
  switch (action.type) {
    case types.hotelCategoriesLoad:
      return {
        ...state,
        hotelCategories: action.payload.hotelCategories,
      };

    case types.hotelCategoryLoad:
      return {
        ...state,
        hotelCategory: action.payload.hotelCategory,
      };

    default:
      return { ...state };
  }
};
