import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { startHotelBySlug } from "../../../../redux/actions/hotel";
import { HotelForm } from "../form/HotelForm";
import { RootState } from "../../../../redux/reducers/index";
import { PreloadIcon } from "../../../../components/common/preload/PreloadView";

// import { RootState } from "../../../../redux/reducers/index";

export const HotelEdit = () => {
  const dispatch = useDispatch();
  const { slug }: any = useParams();

  const { hotel } = useSelector((state: RootState) => state.hotel);
  const [loaidng, setLoading] = useState<boolean>(true);

  const loadHotel = useCallback(async () => {
    await dispatch(startHotelBySlug(slug));
    setLoading(false);
  }, [dispatch, slug]);

  useEffect(() => {
    loadHotel();
  }, [loadHotel]);

  if (loaidng) {
    return <PreloadIcon />;
  }

  if (!hotel.slug) {
    return (
      <div className="hotel-edit-container">
        <h3>Actividad no encontrada</h3>
      </div>
    );
  }

  return (
    <div className="hotel-edit-container">
      <HotelForm {...hotel} />
    </div>
  );
};
