import Swal from "sweetalert2";
import { API } from "../../api";

export const RegisterGoogleId = async (user: IAuth) => {
//   let config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
    
  return await API.POST(`/login/google`, user).then((data: IData) => {
    if (data.ok) {
        // Swal.fire({
        //     position: 'center',
        //     icon: 'success',
        //     title: data.message,
        //     showConfirmButton: false,
        //     timer: 1500
        //   })
      return data.data;
    }
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: data.data.message,
        showConfirmButton: false,
        timer: 1500
      })
    return null;
  });
};
