import { types } from "../types";
// import { UserI } from "../../views/auth/login/LoginI";

const initialState: IAuth = {
  uid: "",
  fullName: "",
  email: "",
  photo: "",
  role: "",
  google_id: "",
  token: "",
};

export const authReducer = (
  state: AuthState = initialState,
  action: AuthAction
): IAuth => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        ...action.payload
      };

    case types.logout:
      return initialState;

    default:
      return state;
  }
};
