import {
  // Button,
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik, Form, FieldArray } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  // activity as activityAction,
  startUpserActivity,
} from "../../../../redux/actions/activity";
import { startAllTopics } from "../../../../redux/actions/topic";

import { RootState } from "../../../../redux/reducers/index";
import { ActivityFormImageList } from "./ActividyFormImageList";
import { ActivityFormPriceList } from "./ActivityFormPriceList";

export const ActivityForm = (activity: IActivity) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { topics } = useSelector((state: RootState) => state.topic);
  const { token } = useSelector((state: RootState) => state.auth);

  const [redirect, setRedirect] = useState<string | null>("");
  const [images, setImages] = useState<IImages[]>([]);
  const [videos, setVideos] = useState<IVideos[]>([]);
  const [prices, setPrices] = useState<IPrices[]>([]);
  const [delImages, setDelImages] = useState<number[]>([]);
  const [delVideos, setDelVideos] = useState<number[]>([]);
  const [delPrices, setDelPrices] = useState<number[]>([]);

  const loadTopic = useCallback(async () => {
    await dispatch(startAllTopics());
  }, [dispatch]);

  useEffect(() => {
    loadTopic();
  }, [loadTopic]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="activity-edit-container">
      <Formik
        initialValues={activity}
        validate={(values) => {
          let errors: any = {};
          // Email
          if (!values.name) errors.name = "Nombre de actividad requerido";
          if (!values.topic_id)
            errors.topic_id = "El tema de actividad requerido";
          if (!prices.length && !values.prices.length)
            errors.prices = "Almenos debes ingresar un precio";
          if (!images.length && !values.images.length)
            errors.images = "Almenos debes ingresar una imagen";
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          
          // handleLogin(values);
          let res = await dispatch(
            startUpserActivity(
              values,
              delImages,
              delVideos,
              delPrices,
              images,
              videos,
              prices,
              token
            )
          );
          if (res) setRedirect(res.toString());
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} id="form-activity">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="name"
                  label="Nombre de la actividad *"
                  type="text"
                  name="name"
                  autoComplete="off"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && touched.name && (
                  <Alert severity="error">{errors.name}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Categoria de actividad *
                  </InputLabel>
                  <Select
                    name="topic_id"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={values?.topic_id}
                    onChange={handleChange}
                  >
                    {topics.map((topic, i) => (
                      <MenuItem key={topic.id} value={topic.id}>
                        {topic.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.topic_id && touched.topic_id && (
                  <Alert severity="error">{errors.topic_id}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="description"
                  label="Descripción"
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.description && touched.description && (
                  <Alert severity="error">{errors.description}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="review"
                  label="Reseña"
                  type="text"
                  name="review"
                  value={values.review}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.review && touched.review && (
                  <Alert severity="error">{errors.review}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="include"
                  label="Incluye"
                  type="text"
                  name="include"
                  value={values.include}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.include && touched.include && (
                  <Alert severity="error">{errors.include}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formControl}
                  id="note"
                  label="Nota"
                  type="text"
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  multiline
                  rows={6}
                />
                {errors.note && touched.note && (
                  <Alert severity="error">{errors.note}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Precios *
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="prices"
                    render={(arrayHelpers) => (
                      <ActivityFormPriceList
                        priceDelete={(index: number) => {
                          setDelPrices([...delPrices, values.prices[index].id]);
                          arrayHelpers.remove(index);
                        }}
                        pricesUpdate={(index: number, newPrices: any) =>
                          // setPrices([...newPrices])
                          arrayHelpers.replace(index, newPrices)
                        }
                        prices={values.prices}
                        errors={errors}
                        type="price"
                      />
                    )}
                  />
                  <ActivityFormPriceList
                    pricesUpdate={(newPrices: any) => setPrices([...newPrices])}
                    prices={prices}
                    errors={errors}
                    type="newPrices"
                  />
                </Grid>
                {errors.prices && touched.prices && (
                  <Alert severity="error">{errors.prices}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Imagenes *
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="images"
                    render={(arrayHelpers) => (
                      <ActivityFormImageList
                        filesUpdate={(index: number) => {
                          setDelImages([...delImages, values.images[index].id]);
                          arrayHelpers.remove(index);
                        }}
                        files={values.images}
                        type="image"
                      />
                    )}
                  />
                  <ActivityFormImageList
                    filesUpdate={(newFiles: any) => setImages([...newFiles])}
                    files={images}
                    type="newImages"
                  />
                </Grid>
                {errors.images && touched.images && (
                  <Alert severity="error">{errors.images}</Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="demo-simple-select-filled-label">
                  Videos
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <FieldArray
                    name="videos"
                    render={(arrayHelpers) => (
                      <ActivityFormImageList
                        filesUpdate={(index: number) => {
                          setDelVideos([...delVideos, values.videos[index].id]);
                          arrayHelpers.remove(index);
                        }}
                        files={values.videos}
                        type="video"
                      />
                    )}
                  />

                  <ActivityFormImageList
                    filesUpdate={(newFiles: any) => setVideos([...newFiles])}
                    files={videos}
                    type="newVideos"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  })
);
