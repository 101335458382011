import { API } from "../../api";

interface ISaveHotel {
  hotel: IHotel;
  delImages: number[];
  delVideos: number[];
  delPrices: number[];
  newImages: IImages[];
  newVideos: IVideos[];
  newPrices: IPrices[];
}

export const PutUpdateHotel = async (
  data: ISaveHotel,
  token: string
) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await API.PUT(`/hotel/${data.hotel.id}`, data, config).then((data: IData) => data);
};
