import { NavLink, withRouter } from "react-router-dom";
import React from "react";

import logo from "../../../../assets/images/logo.png";
import "./styles.scss";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { Menu as MenuBar } from "@material-ui/icons";
import { RootState } from "../../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import { startLogout } from "../../../../redux/actions/auth";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      flex: "1 0 auto",
      margin: theme.spacing(1),
    },
  })
);

const NavBarCreate = () => {
  const {  photo, fullName } = useSelector(
    (state: RootState) => state.auth
  );

  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state: RootState) => state.ui);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(startLogout());
  };

  return (
    <>
      <div className={classes.container}>
        <Hidden mdUp>
          <nav className="navbar-container-md">
            <img src={logo} alt="" height="30" />
            <div>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}
              >
                <MenuBar />
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <ul className="ul-md">
                  <li>
                    <NavLink
                      activeClassName="navbar-active c1"
                      className="c1"
                      exact
                      to="/"
                    >
                      Inicio
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="navbar-active c2"
                      className="c2"
                      exact
                      to="/hoteles"
                    >
                      Hotoles
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="navbar-active c3"
                      className="c3"
                      exact
                      to="/paquetes"
                    >
                      Paquetes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="navbar-active c4"
                      className="c4"
                      exact
                      to="/actividades"
                    >
                      Actividades
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="navbar-active c5"
                      className="c5"
                      exact
                      to="/contacto"
                    >
                      Contactanos
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      activeClassName="navbar-active c6"
                      className="c6"
                      exact
                      to="/quienesSomos"
                    >
                      ¿Quienes somos?
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink exact to="/login">
                      <Button variant="text" color="primary">
                        Ingresar
                      </Button>
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink exact to="/register">
                      <Button variant="text" color="primary">
                        Registrarse
                      </Button>
                    </NavLink>
                  </li> */}
                </ul>
              </StyledMenu>
            </div>
          </nav>
        </Hidden>

        <Hidden smDown>
          <nav className="navbar-container">
            <img src={logo} alt="" height="70" />
            <ul>
              <li>
                <NavLink
                  activeClassName="navbar-active c1"
                  className="c1"
                  exact
                  to="/"
                >
                  Inicio
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="navbar-active c2"
                  className="c2"
                  exact
                  to="/hoteles"
                >
                  Hotoles
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="navbar-active c3"
                  className="c3"
                  exact
                  to="/paquetes"
                >
                  Paquetes
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="navbar-active c4"
                  className="c4"
                  exact
                  to="/actividades"
                >
                  Actividades
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName="navbar-active c5"
                  className="c5"
                  exact
                  to="/contacto"
                >
                  Contactanos
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  activeClassName="navbar-active c6"
                  className="c6"
                  exact
                  to="/quienesSomos"
                >
                  ¿Quienes somos?
                </NavLink>
              </li> */}
            </ul>
            <div className="btn-auth">
              {!isLoggedIn ? (
                <>
                  {/* <NavLink activeClassName="navbar-active c6" exact to="/login">
                    <Button variant="text" color="primary">
                      Ingresar
                    </Button>
                  </NavLink> */}
                  {/* <NavLink
                    activeClassName="navbar-active c4"
                    exact
                    to="/register"
                  >
                    <Button variant="text" color="primary">
                      Registrarse
                    </Button>
                  </NavLink> */}
                </>
              ) : (
                <>
                  {/* <NavLink
                    activeClassName="navbar-active c4"
                    exact
                    to="/register"
                  >
                    <Button variant="text" color="primary">
                      Salir
                    </Button>
                  </NavLink> */}
                    <Avatar
                      className="navbar-avatar"
                    onClick={handleClick}
                    alt="Travis Howard"
                    src={photo || ""}
                  />
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <h6>{fullName}</h6>
                    <NavLink activeClassName="navbar-active c4" exact to="/">
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleLogout}
                      >
                        Salir
                      </Button>
                    </NavLink>
                  </StyledMenu>
                </>
              )}
            </div>
          </nav>
        </Hidden>
      </div>
    </>
  );
};

export const NavBar = withRouter(NavBarCreate);
