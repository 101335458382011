import { types } from "../types";
// import { UserI } from "../../views/auth/login/LoginI";

export const initialStateContact: ContactState = {
  contacts: [],
  contact: {
    id: 0,
    fullName: "",
    cellPhone: "",
    email: "",
    subject: "",
    message: "",
    state: {
      id: 0,
      name: "",
    },
    created_at: "",
  },
};

export const contactReducer = (
  state: ContactState = { ...initialStateContact },
  action: ContactAction
): ContactState => {
  switch (action.type) {
    case types.contactsLoad:
      return {
        ...state,
        contacts: action.payload.contacts,
      };

    case types.contactLoad:
      return {
        ...state,
        contact: action.payload.contact,
      };

    default:
      return { ...state };
  }
};
