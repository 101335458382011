import { types } from "../types";
// import { UserI } from "../../views/auth/login/LoginI";

export const initialStateHotel: HotelState = {
  hotels: [],
  hotel: {
    id: 0,
    name: "",
    description: "",
    include: "",
    note: "",
    slug: "",
    hotelCategory_id: 0,
    state_id: 0,
    activities: [],
    images: [],
    videos: [],
    prices: [],
  },
  hotelActivity: {
    activity: {
      id: 0,
      name: "",
      description: "",
      review: "",
      include: "",
      note: "",
      slug: "",
      topic_id: 0,
      state_id: 0,
      private: "si",
      images: [],
      videos: [],
      prices: [],
    },
    newImages: [],
    newVideos: [],
    newPrices: [],
  
    delImages: [],
    delVideos: [],
    delPrices: [],
  },
};

export const hotelReducer = (
  state: HotelState = initialStateHotel,
  action: HotelAction
): HotelState => {
  switch (action.type) {
    case types.hotelsLoad:
      return {
        ...state,
        hotels: action.payload.hotels,
      };

    case types.hotelLoad:
      return {
        ...state,
        hotel: action.payload.hotel,
      };

    default:
      return { ...state };
  }
};
