import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, List } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import { RootState } from "../../../../redux/reducers/index";
import { startPackageBySlug } from "../../../../redux/actions/package";
import "./styles.scss";
import { PackageShowSwipe } from "./PackageShowSwipe";
import { PreloadIcon } from "../../../../components/common/preload/PreloadView";
import { ActivityCard } from "../../../activity/components/card/ActivityCard";
import { HotelCard } from "../../../hotel/components/card/HotelCard";

export const PackageShow = () => {
  const dispatch = useDispatch();
  const { slug }: any = useParams();
  const { pathname } = useLocation();

  const { package: packag } = useSelector((state: RootState) => state.package);
  const [loaidng, setLoading] = useState<boolean>(true);

  const loadPackage = useCallback(async () => {
    await dispatch(startPackageBySlug(slug));
    setLoading(false);
  }, [dispatch, slug]);

  useEffect(() => {
    loadPackage();
  }, [loadPackage]);

  if (loaidng) {
    return <PreloadIcon />;
  }

  if (!packag?.slug) {
    return (
      <div className="package-show-container">
        <Grid container spacing={5}>
          <Grid item xs={12} md={5}>
            <h3>Paquete no encontrado</h3>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div
      className={!pathname.includes("admin") ? "package-show-container" : ""}
    >
      <Grid container spacing={5}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <PackageShowSwipe {...packag} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>{packag.name}</h3>
              <hr />
              <br />
              <strong>Precios</strong>
              {packag.prices.map((price: IPrices) => (
                <List key={price.id}>
                  <div>
                    <strong>{price.name}</strong>
                    <p>
                      {Intl.NumberFormat("ES-CO", {
                        style: "currency",
                        currency: "COP",
                        maximumFractionDigits: 0,
                      }).format(price.value)}
                    </p>
                  </div>
                </List>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {packag.description && (
              <Grid item xs={12} md={6}>
                <strong>Descripcio</strong>
                <p>{packag.description}</p>
              </Grid>
            )}
            {packag.include && (
              <Grid item xs={12} md={6}>
                <strong>Incluye</strong>
                <p>{packag.include}</p>
              </Grid>
            )}
            {packag.note && (
              <Grid item xs={12} md={6}>
                <strong>Nota</strong>
                <p>{packag.note}</p>
              </Grid>
            )}
          </Grid>
        </Grid>
        {packag.hotels && (
          <>
            <Grid item xs={12}>
              <h2>Hotel del paquete</h2>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {packag.hotels.length ? (
                    packag.hotels.map((hotel: IHotel, i) => (
                      <Grid key={hotel.id} item xs={12} sm={6} lg={3}>
                        <HotelCard {...hotel} />
                      </Grid>
                    ))
                ): <p>Este paquete no incluye hotel</p>}
              </Grid>
            </Grid>
          </>
        )}
        {packag.activities && (
          <>
            <Grid item xs={12}>
              <h2>Actividades del paquete</h2>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {packag.activities.length ? (
                    packag.activities.map((activity: any, i) => (
                      <Grid key={activity.id} item xs={12} sm={6} lg={3}>
                        <ActivityCard {...activity} />
                      </Grid>
                    ))
                ): <p>Este paquete no incluye actividades</p>}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
