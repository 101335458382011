import { HomeInformation } from "./components/HomeInformation";
import { HomeSubscribe } from "./components/HomeSubscribe";
// import { Footer } from '../../components/ui/Footer';
import { HotelList } from "../hotel/components/list/HotelList";

import "./styles.scss";
import { PackageList } from "../package/components/list/PackageList";

export const HomeView = () => {
  return (
    <section>
      <div className="home-last-hotel">
        <h2>Últimos paquetes turísticos</h2>
        <p>Estos son los paquete más recientes que tenemos para tí.</p>
        <br />
        <PackageList />
      </div>
      <HomeInformation />
      <div className="home-last-hotel">
        <h2>Nuevas Posadas turísticas</h2>
        <HotelList />
      </div>

      <HomeSubscribe />

      {/* <Footer /> */}
    </section>
  );
};
