import { API } from "../../api";

interface IConact {
  fullName: string;
  email: string;
  cellPhone: string;
  subject: string;
  message: string;
}

export const postCreateContact = async (data: IConact) => {
  return await API.POST(`/contact`, data).then((data: IData) => data);
};
