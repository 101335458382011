import React from "react";

export const HomeView = () => {
  return (
    <>
      <div className="admin-container-header">
        <h2>Inicio</h2>
      </div>
      <hr />
      <div className="admin-container-body">Desarrollo</div>
    </>
  );
};
