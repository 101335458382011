import { HotelForm } from "../form/HotelForm";
import { initialStateHotel } from "../../../../redux/reducers/hotelReducer";

export const HotelCreate = () => {
  const hotel: IHotel = initialStateHotel.hotel;

  return (
    <div className="hotel-edit-container">
      <HotelForm {...hotel} />
    </div>
  );
};
