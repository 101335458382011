import { useCallback, useEffect, useState } from "react";
import { HashRouter, Switch, Redirect } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { ClientRouter } from "./ClientRouter";
import { AdminRouter } from "./AdminRouter";
import { PrivateRoute } from "./PrivateRoute";
import { getUserAuth } from "../redux/actions/auth";
import { PublicRoute } from "./PublicRoute";
import { PreloadView } from "../components/common/preload/PreloadView";
import { RootState } from "../redux/reducers";

export const AppRouter = () => {
  const RolesAdmin = ["Admin", "Colaborador"];

  const dispatch = useDispatch();
  const { role } = useSelector((state: RootState) => state.auth);
  const { isLoggedIn } = useSelector((state: RootState) => state.ui);

  const [checking, setChecking] = useState(true);

  const getUser = useCallback(async () => {
    let token = localStorage.getItem("token");
    if (token) {
      await dispatch(getUserAuth(token));
    }

    setChecking(false);
  }, [dispatch, setChecking]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  if (checking) {
    return <PreloadView />;
  }

  return (
    <HashRouter>
      <div>
        <Switch>
          <PrivateRoute
            strict
            isAuthenticated={isLoggedIn}
            path="/admin"
            component={AdminRouter}
          />
          {RolesAdmin.includes(role) && isLoggedIn && <Redirect to="/admin" />}
          <PublicRoute
            path="/"
            component={ClientRouter}
            isAuthenticated={isLoggedIn}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </HashRouter>
  );
};
