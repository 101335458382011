import { Avatar, Button, withStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { startLogout } from "../../../../../redux/actions/auth";
import "./styles.scss";

export const AvatarProfile = () => {
  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
      padding: "0 10px",
    },
  })((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const { photo, fullName } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(startLogout());
  };

  return (
    <>
      <Avatar
        className="navbar-avatar"
        onClick={handleClick}
        alt="Travis Howard"
        src={photo || ""}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <h4>{fullName}</h4>
        <NavLink activeClassName="navbar-active c4" exact to="/">
          <Button variant="text" color="primary" onClick={handleLogout}>
            Salir
          </Button>
        </NavLink>
      </StyledMenu>
    </>
  );
};
