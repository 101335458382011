import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, List } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import { RootState } from "../../../../redux/reducers/index";
import { startActivityBySlug } from "../../../../redux/actions/activity";
import "./styles.scss";
import { ActivityShowSwipe } from "./ActivityShowSwipe";
import { PreloadIcon } from "../../../../components/common/preload/PreloadView";

export const ActivityShow = () => {
  const dispatch = useDispatch();
  const { slug }: any = useParams();
  const { pathname } = useLocation();

  const { activity } = useSelector((state: RootState) => state.activity);
  const [loaidng, setLoading] = useState<boolean>(true);

  const loadActivity = useCallback(async () => {
    await dispatch(startActivityBySlug(slug));
    setLoading(false);
  }, [dispatch, slug]);

  useEffect(() => {
    loadActivity();
  }, [loadActivity]);

  if (loaidng) {
    return <PreloadIcon />;
  }

  if (!activity.slug) {
    return (
      <div className="activity-show-container">
        <Grid container spacing={5}>
          <Grid item xs={12} md={5}>
            <h3>Actividad no encontrada</h3>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div
      className={!pathname.includes("admin") ? "activity-show-container" : ""}
    >
      <Grid container spacing={5}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <ActivityShowSwipe {...activity} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>{activity.name}</h3>
              <hr />
              <br />
              <strong>Precios</strong>
              {activity.prices.map((price: IPrices) => (
                <List key={price.id}>
                  <div>
                    <strong>{price.name}</strong>
                    <p>
                      {Intl.NumberFormat("ES-CO", {
                        style: "currency",
                        currency: "COP",
                        maximumFractionDigits: 0,
                      }).format(price.value)}
                    </p>
                  </div>
                </List>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {activity.description && (
              <Grid item xs={12} md={6}>
                <strong>Descripcio</strong>
                <p>{activity.description}</p>
              </Grid>
            )}
            {activity.review && (
              <Grid item xs={12} md={6}>
                <strong>Reseña</strong>
                <p>{activity.review}</p>
              </Grid>
            )}
            {activity.include && (
              <Grid item xs={12} md={6}>
                <strong>Incluye</strong>
                <p>{activity.include}</p>
              </Grid>
            )}
            {activity.note && (
              <Grid item xs={12} md={6}>
                <strong>Nota</strong>
                <p>{activity.note}</p>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
