import { Button, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { URL_IMAGES } from "../../../../api";

import "./styles.scss";

export const ActivityCard = ({
  name,
  description,
  images=[],
  slug,
}: IActivity) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <div className="activivy-card">
      <figure>
        <img
          src={`${URL_IMAGES}${images[0]?.url}`}
          alt=""
          height="300"
          width="100%"
          className={classes.img}
        />
      </figure>
      <div className="activity-card-detail">
        <h3>{name}</h3>
        <p>{description}</p>
        {/* <Button
          variant="outlined"
          color="primary"
          className="activity-btn-detail"
        >
          Ver detalles
        </Button> */}
        <Link
          to={`${
            pathname.includes("admin")
              ? "/admin/actividades/show"
              : "/actividades/detalles"
          }/${slug}`}
          onClick={() =>
            localStorage.setItem("posScrollY", window.scrollY.toString())
          }
        >
          <Button
            variant="outlined"
            color="primary"
            className="activity-btn-detail"
          >
            Ver detalles
          </Button>
        </Link>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  img: {
    objectFit: "cover",
  },
}));
