import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, List } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import { RootState } from "../../../../redux/reducers/index";
import { startHotelBySlug } from "../../../../redux/actions/hotel";
import { ActivityCard } from "../../../activity/components/card/ActivityCard";
import "./styles.scss";
import { HotelShowSwipe } from "./HotelShowSwipe";
import { PreloadIcon } from "../../../../components/common/preload/PreloadView";

export const HotelShow = () => {
  const dispatch = useDispatch();
  const { slug }: any = useParams();
  const { pathname } = useLocation();

  const { hotel } = useSelector((state: RootState) => state.hotel);
  const [loaidng, setLoading] = useState<boolean>(true);

  const loadHotel = useCallback(async () => {
    await dispatch(startHotelBySlug(slug));
    setLoading(false);
  }, [dispatch, slug]);

  useEffect(() => {
    loadHotel();
  }, [loadHotel]);

  if (loaidng) {
    return <PreloadIcon />;
  }

  if (!hotel.slug) {
    return (
      <div className="hotel-show-container">
        <Grid container spacing={5}>
          <Grid item xs={12} md={5}>
            <h3>Actividad no encontrada</h3>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={!pathname.includes("admin") ? "hotel-show-container" : ""}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <HotelShowSwipe {...hotel} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>{hotel.name}</h3>
              <hr />
              <br />
              <strong>Precios</strong>
              {hotel.prices.map((price: IPrices) => (
                <List key={price.id}>
                  <div>
                    <strong>{price.name}</strong>
                    <p>
                      {Intl.NumberFormat("ES-CO", {
                        style: "currency",
                        currency: "COP",
                        maximumFractionDigits: 0,
                      }).format(price.value)}
                    </p>
                  </div>
                </List>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {hotel.description && (
              <Grid item xs={12} md={6}>
                <strong>Descripcio</strong>
                <p>{hotel.description}</p>
              </Grid>
            )}
            {hotel.include && (
              <Grid item xs={12} md={6}>
                <strong>Incluye</strong>
                <p>{hotel.include}</p>
              </Grid>
            )}
            {hotel.note && (
              <Grid item xs={12} md={6}>
                <strong>Nota</strong>
                <p>{hotel.note}</p>
              </Grid>
            )}
          </Grid>
        </Grid>
        {hotel.activities && (
          <>
            <Grid item xs={12}>
              <h2>Actividades del hotel</h2>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {hotel.activities.map((activity: IHotelActivity, i) => (
                  <Grid key={activity.activity.id} item xs={12} sm={6} lg={3}>
                    <ActivityCard {...activity.activity} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
