import { Card, CardContent, Fab, Grid, makeStyles } from "@material-ui/core";
import { Pool } from "@material-ui/icons";
import { initialStateHotel } from "../../../../redux/reducers/hotelReducer";
import { HotelFormActivityCard } from "./HotelFormActivityCard";

export const HotelFormActivityList = ({
  activities,
  activitiesUpdate,
  activityDelete,
  errors,
  type,
}: any) => {
  const activity: IHotelActivity = initialStateHotel.hotelActivity;
  const classes = useStyles();
  const activitiesAdd = () => {
    activities.push({ ...activity, id: activities.length });
    activitiesUpdate(activities);
  };

  const activitiesDel = (delActivities: IHotelActivity) => {
    if (type === "newActivities") {
      activities.splice(activities.indexOf(delActivities), 1);
      activitiesUpdate(activities);
    } else {
      activityDelete(activities.indexOf(delActivities));
    }
  };

  const handleChageActivity = ({ value, name }: any, index: number) => {
    if (type === "newActivities") {
      activities[index].activity[name] = value;
      activitiesUpdate(activities);
    } else {
      activities[index].activity[name] = value;
      activitiesUpdate(index, activities[index]);
    }
  };

  const handleChageFileAndPrice = ({ value, name }: any, index: number) => {
    if (type === "newActivities") {
      activities[index][name] = value;
      activitiesUpdate(activities);
    } else {
      activities[index][name] = value;
      activitiesUpdate(index, activities[index]);
    }
  };

  return (
    <>
      {activities.map((activity: IHotelActivity, index: number) => (
        <HotelFormActivityCard
          key={activity.activity.id + index}
          activitiesDel={activitiesDel}
          activity={activity}
          i={index}
          errors={errors}
          handleChageActivity={(target: any) =>
            handleChageActivity(target, index)
          }
          handleChageFileAndPrice={(target: any) =>
            handleChageFileAndPrice(target, index)
          }
        />
      ))}
      {type === "newActivities" && (
        <Grid item xs={12} sm={3}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Fab onClick={activitiesAdd} color="secondary">
                <Pool />
              </Fab>
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: "flex",
    justifyContent: "center",
  },
}));
