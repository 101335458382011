import axios from "axios";

// export const URL_SERVER = "https://nuqui-tours-client-xhmrx.ondigitalocean.app/nuqui-tours-api";
// export const URL_SERVER = "http://192.168.0.2:8000";
// export const URL_SERVER = "http://localhost:8000";
// export const URL_API = `http://localhost:8000/api`;
export const URL_API = `https://api.nuquitours.com.co/api`;
export const URL_IMAGES = `https://nuquitoursfiles.nyc3.digitaloceanspaces.com/images/`;
export const URL_VIDEOS = `https://nuquitoursfiles.nyc3.digitaloceanspaces.com/videos/`;

class Api {
  GET = async (url: string, config = {}) => {
    return axios
      .get(`${URL_API}${url}`, config)
      .then(({ data }) => data)
      .catch((e) => e);
  };

  POST = async (url: string, data = {}, config = {}) => {
    return axios
      .post(`${URL_API}${url}`, data, config)
      .then(({ data }) => data)
      .catch((e) => e);
  };

  PUT = async (url: string, data = {}, config = {}) => {
    return axios
      .put(`${URL_API}${url}`, data, config)
      .then(({ data }) => data)
      .catch((e) => e);
  };

  DEL = async (url: string, config = {}) => {
    return axios
      .delete(`${URL_API}${url}`, config)
      .then(({ data }) => data)
      .catch((e) => e);
  };
}

export const API = new Api();

// exports = {
//   API: new Api(),
//   URL_API,
// };
