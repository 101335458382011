import { PackageForm } from "../form/PackageForm";
import { initialStatePackage } from "../../../../redux/reducers/packageReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { PreloadIcon } from "../../../../components/common/preload/PreloadView";
import { useCallback, useEffect, useState } from "react";
import { startAllHotels } from "../../../../redux/actions/hotel";
import { startAllActivities } from "../../../../redux/actions/activity";

export const PackageCreate = () => {
  const dispatch = useDispatch();
  const packag: IPackage = initialStatePackage.package;
  const { hotels: hotels_list } = useSelector(
    (state: RootState) => state.hotel
  );

  const { activities: activities_list } = useSelector(
    (state: RootState) => state.activity
  );
  const [loaidng, setLoading] = useState<boolean>(true);

  const loadData = useCallback(async () => {
    await dispatch(startAllHotels());
    await dispatch(startAllActivities());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loaidng) {
    return <PreloadIcon />;
  }

  return (
    <div className="package-edit-container">
      <PackageForm
        packag={{ ...packag }}
        hotels_list={hotels_list}
        activities_list={activities_list}
      />
    </div>
  );
};
