import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Button, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { RootState } from "../../../../../redux/reducers";
import { PackageShow } from "../../../../package/components/show/PackageShow";
import "./styles.scss";

export const PackageAdminShow = () => {
  const { package: packag } = useSelector((state: RootState) => state.package);
  const history = useHistory();
  
  return (
    <>
      <Grid container spacing={1} className="admin-container-header">
        <Grid item xs={12} sm={6}>
          <h2>Detalle del paquete</h2>
        </Grid>
        {packag?.slug && (
          <Grid item xs={12} sm={6} className="admin-container-header-buttons">
            <Button
              type="button"
              variant="contained"
              color="default"
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />
              Atras
            </Button>
            {/* <Link to={`/admin/paquetes`}>
              <Button type="button" variant="contained" color="default">
                Atras
              </Button>
            </Link> */}
            <Link to={`/admin/paquetes/edit/${packag.slug}`}>
              <Button variant="contained" color="primary">
                Actualizar
              </Button>
            </Link>
            {/* <Button variant="contained" color="secondary">
            Desabilitar
          </Button> */}
          </Grid>
        )}
      </Grid>
      <hr />
      <div className="admin-container-body">
        <PackageShow />
      </div>
    </>
  );
};
