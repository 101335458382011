import "./styles.scss";
// import bannerHome from "../../assets/images/bannerHome.jpg"
import { NavBar } from "./NavBar";
import { useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";

export const Header = () => {
  const { pathname } = useLocation();

  let view = "";
  let isAuth = false;

  switch (pathname) {
    case "/hoteles":
      view = "view2";
      break;
    case "/paquetes":
      view = "view3";
      break;
    case "/actividades":
      view = "view4";
      break;
    case "/contacto":
      view = "view5";
      break;
    case "/quienesSomos":
      view = "view6";
      break;
    case "/login":
      isAuth = true;
      break;
    case "/register":
      isAuth = true;
      break;
    default:
      view = "view1";
  }

  return (
    <div className={`header-container${isAuth ? "-auth" : ""} ${view}`}>
      <NavBar />
      {!isAuth && (
        <div className="header-opacity">
          <div className="header-body">
            <h1>
              Tú elijes <span>Tus paquetes</span> turísticos
            </h1>
            <br />
            <p>
              Tienda de paquetes turísticos ubicados en la cabecera munucipal
              Nuquí y sus alrededores
            </p>
            <br />
            <div className="header-buttons">
              <Button variant="contained" color="inherit">
                Posadas turisticas
              </Button>
              <Button variant="outlined" color="primary">
                Paquetes turisticos
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
