import Swal from "sweetalert2";
import { DeletePackage } from "../../helpers/package/DeletePackage";
import { GetPackageBySlug } from "../../helpers/package/GetPackageBySlug";
import { GetAllPackages } from "../../helpers/package/GetAllPackages";
import { PostSavePackage } from "../../helpers/package/PostSavePackage";
import { PutUpdatePackage } from "../../helpers/package/PutUpdatePackage";
import { types } from "../types";

export const packages = (packages: IPackage[]) => ({
  type: types.packagesLoad,
  payload: { packages },
});

export const packag = (packag: IPackage) => ({
  type: types.packageLoad,
  payload: { package: packag },
});

export const startAllPackages = () => {
  return async (dispatch: any) => {
    // Swal.fire({
    //   title: "Cargando..",
    //   allowOutsideClick: false,
    //   // html: 'I will close in <b></b> milliseconds.',
    //   // timer: 2000,
    //   // timerProgressBar: true,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });

    let allPackages = await GetAllPackages();
    await dispatch(packages(allPackages));
    // Swal.hideLoading();
    // Swal.close();

    // dispatch(finishLoading());
  };
};

export const startPackageBySlug = (slug: string) => {
  return async (dispatch: any) => {
    // Swal.fire({
    //   title: "Cargando..",
    //   allowOutsideClick: false,
    //   // html: 'I will close in <b></b> milliseconds.',
    //   // timer: 2000,
    //   // timerProgressBar: true,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });
    let getPackage = await GetPackageBySlug(slug);
    getPackage.hotels = getPackage.hotels.map((item: any) => {
      delete item.hotel.created_at;
      delete item.hotel.updated_at;
      return item.hotel;
    });
    getPackage.activities = getPackage.activities.map((item: any) => {
      delete item.activity.created_at;
      delete item.activity.updated_at;
      return item.activity;
    });
    await dispatch(packag(getPackage));

    // Swal.hideLoading();
    // Swal.close();

    // dispatch(finishLoading());
  };
};

export const startUpserPackage = (
  packag: IPackage,
  delImages: number[],
  delVideos: number[],
  delPrices: number[],
  newImages: IImages[],
  newVideos: IVideos[],
  newPrices: IPrices[],
  token: string,
  hotels: IHotel[],
  activities: IActivity[]
) => {
  return async (dispatch: any) => {
    Swal.fire({
      title: "Guardando..",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let data = {
      package: packag,
      delImages,
      delVideos,
      delPrices,
      newImages,
      newVideos,
      newPrices,
      hotels,
      activities,
    };

    let res = null;

    if (packag.id) res = await PutUpdatePackage(data, token);
    else res = await PostSavePackage(data, token);

    Swal.hideLoading();
    Swal.close();

    if (res.ok) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
      return null;
    }

    return `/admin/paquetes/show/${res.data.slug}`;
  };
};

export const startDeletePackage = (id: number, token: string) => {
  return async (dispatch: any) =>
    await Swal.fire({
      title: "¿Está seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar actividad!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Eliminando..",
          allowOutsideClick: false,
          // html: 'I will close in <b></b> milliseconds.',
          // timer: 2000,
          // timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        let res = await DeletePackage(id, token);

        Swal.hideLoading();
        Swal.close();

        if (res.ok) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: false,
            timer: 1500,
          });
          return null;
        }

        return `/admin/paquetes`;
      }
    });
};
