import { Card, CardContent, Fab, Grid, makeStyles } from "@material-ui/core";
import { VideoLibrary, PhotoLibrary } from "@material-ui/icons";
import React from "react";
import { HotelFormImageCard } from "./HotelFormImageCard";

export const HotelFormImageList = React.memo(
  ({ files, filesUpdate, type }: any) => {
    const classes = useStyles();
    const filesAdd = (base64: string) => {
      files.push({
        id: files.length,
        url: base64,
      });
      filesUpdate(files);
    };

    const fileDel = (delImages: IImages | IVideos) => {
      if (type === "newVideos" || type === "newImages") {
        files.splice(files.indexOf(delImages), 1);
        filesUpdate(files);
      } else {
        filesUpdate(files.indexOf(delImages));
      }
    };

    const activeFilesImage = () => {
      let files = document.querySelector<HTMLInputElement>("#upload-images");
      files?.click();
    };

    const activeFilesVideo = () => {
      let files = document.querySelector<HTMLInputElement>("#upload-videos");
      files?.click();
    };

    const handleFiles = () => {
      let files = document.querySelector<HTMLInputElement>(
        `#${type === "newImages" ? "upload-images" : "upload-videos"}`
      )?.files;

      if (files) {
        Object.keys(files).forEach((f, i) => {
          let reader = new FileReader();
          let preview: any = {};
          reader.onloadend = function () {
            if (files) {
              let base64 =
                typeof reader.result === "string" ? reader.result : "";
              filesAdd(base64);
            }
          };

          if (files) {
            reader.readAsDataURL(files[i]);
          } else {
            if (preview) preview.src = "";
          }
        });
      }
    };

    return (
      <>
        {type === "newImages"
          ? files.map((image: IImages, i: any) => (
              <HotelFormImageCard
                key={image.id + i}
                fileDel={fileDel}
                file={image}
                i={i}
                type={type}
              />
            ))
          : files.map((video: IVideos, i: any) => (
              <HotelFormImageCard
                key={video.id + i}
                fileDel={fileDel}
                file={video}
                i={i}
                type={type}
              />
            ))}
        {(type === "newImages" || type === "newVideos") && (
          <Grid item xs={6} sm={3}>
            <Card>
              <CardContent className={classes.cardContent}>
                {type === "newImages" ? (
                  <Fab
                    onClick={() => activeFilesImage()}
                    color="secondary"
                    aria-label="add"
                  >
                    <PhotoLibrary />
                    <input
                      hidden
                      id="upload-images"
                      type="file"
                      name="images"
                      multiple
                      onChange={handleFiles}
                      accept="image/*"
                    />
                  </Fab>
                ) : (
                  <Fab
                    onClick={() => activeFilesVideo()}
                    color="secondary"
                    aria-label="add"
                  >
                    <VideoLibrary />
                    <input
                      hidden
                      id="upload-videos"
                      type="file"
                      name="videos"
                      multiple
                      onChange={handleFiles}
                      accept="video/*"
                    />
                  </Fab>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
      </>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));
