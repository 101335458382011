import { API } from "../../api";

interface ISavetHotel {
  hotel: IHotel;
  delImages: number[];
  delVideos: number[];
  delPrices: number[];
  newImages: IImages[];
  newVideos: IVideos[];
  newPrices: IPrices[];
}

export const PostSaveHotel = async (data: ISavetHotel, token: string) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await API.POST(`/hotel`, data, config).then((data: IData) => data);
};
