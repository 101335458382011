// import Swal from "sweetalert2";
import { GetHotelCategoryBySlug } from "../../helpers/hotelCategory/GetHotelCategoryBySlug";
import { GetAllHotelCategories } from "../../helpers/hotelCategory/GetAllHotelCategories";
import { types } from "../types";

export const hotelCategories = (hotelCategories: IHotelCategory[]) => ({
  type: types.hotelCategoriesLoad,
  payload: { hotelCategories },
});

export const hotelCategory = (hotelCategory: IHotelCategory) => ({
  type: types.hotelCategoryLoad,
  payload: { hotelCategory },
});

export const startAllHotelCategories = () => {
  return async (dispatch: any) => {
    let allHotelCategories = await GetAllHotelCategories();
    await dispatch(hotelCategories(allHotelCategories));
  };
};

export const startHotelCategoryBySlug = (slug: string) => {
  return async (dispatch: any) => {
    let getHotelCategory = await GetHotelCategoryBySlug(slug);
    await dispatch(hotelCategory(getHotelCategory));
  };
};
