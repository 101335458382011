import { types } from '../types';

export const setError = ( err: string ) => ({
    type: types.uiSetError,
    payload: err
});

export const removeError = () => ({
    type: types.uiRemoveError
});

export const startLoading = () => ({
    type: types.uiStartLoading
})
export const finishLoading = () => ({
    type: types.uiFinishLoading
})

export const startLogged = () => ({
    type: types.uiStartLoading
})

export const finishLogged = () => ({
    type: types.uiFinishLoading
})

export const startChecking = () => ({
    type: types.uiStartChecking
})

export const finishChecking = () => ({
    type: types.uiFinishChecking
})