import { Grid } from '@material-ui/core';
import { IHomeCardInfo } from './Ihome';

import "./styles.scss"

export const HomeCardInfo = ({logo, title, text}: IHomeCardInfo) => {
    
    return (
            <Grid container className="home-card-container">
                <Grid item xs={12} md={3} >
                    <figure>
                        <img src={logo} alt={`Logo ${title}`} />
                    </figure>
                </Grid>
                <Grid item xs={12} md={9} >
                    <h3>{title}</h3>
                    <br />
                    <p>{text}</p>
                </Grid>
            </Grid>
    )
}
