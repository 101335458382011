import PropTypes from "prop-types";
import { Route, Redirect, useLocation } from "react-router-dom";

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  const { pathname } = useLocation();
  const URLRedirect = ["/login", "/register"];

  if (isAuthenticated && URLRedirect.includes(pathname)) {
    return <Route {...rest} component={(props) => <Redirect to="/" />} />;
  }
  return <Route {...rest} component={(props) => <Component {...props} />} />;
};

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};
