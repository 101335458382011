import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";

interface Column {
  id: "fullName" | "cellPhone" | "email" | "subject" | "created_at" | "state";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "fullName", label: "Nombre completo", minWidth: 170 },
  { id: "cellPhone", label: "Celular", minWidth: 100 },
  {
    id: "email",
    label: "Correo",
    minWidth: 170,
    align: "right",
  },
  {
    id: "subject",
    label: "Asunto",
    minWidth: 170,
    align: "right",
  },
  {
    id: "created_at",
    label: "Fecha",
    minWidth: 170,
    align: "right",
  },
  {
    id: "state",
    label: "Estado",
    minWidth: 170,
    align: "right",
    // format: (value: number) => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
    width: "100%",
  },
});

export default function StickyHeadTable(props: any) {
  const rows = props.data;

  const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, i: number) => {
                return (
                  <TableRow
                    onClick={() => history.push(`/admin/contactos/${row.id}`)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{i + 1}</TableCell>
                    {columns.map((column: any, i) => {
                      console.log(column);
                      let value = column ? row[column.id] : {};
                      value = value?.name ? value.name : value ? value : "";
                      return (
                        <TableCell
                          key={i}
                          align={column.align}
                          style={{
                            color:
                              column?.id === "state" && value === "Sin leer"
                                ? "green"
                                : "",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
