import { ActivityForm } from "../form/ActivityForm";
import { initialStateActivity } from "../../../../redux/reducers/activityReducer";

export const ActivityCreate = () => {
  const activity: IActivity = initialStateActivity.activity;

  return (
    <div className="activity-edit-container">
      <ActivityForm {...activity} />
    </div>
  );
};
