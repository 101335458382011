import { combineReducers } from "redux";
import { activityReducer } from "./activityReducer";
import { authReducer } from "./authReducer";
import { uiReducer } from "./uiReducer";
import { topicReducer } from "./topicReducer";
import { hotelCategoryReducer } from "./hotelCategoryReducer";
import { contactReducer } from "./contactReducer";
import { hotelReducer } from "./hotelReducer";
import { packageReducer } from "./packageReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  activity: activityReducer,
  package: packageReducer,
  hotel: hotelReducer,
  topic: topicReducer,
  hotelCategory: hotelCategoryReducer,
  contact: contactReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
