import Swal from "sweetalert2";

import { firebase, googleAuthProvider } from "../../firebase/firebase-config";
import { GetUserAuth } from "../../helpers/auth/GetUserAuth";
import { LoginCredentials } from "../../helpers/auth/LoginCredentials";
import { RegisterGoogleId } from "../../helpers/auth/RegisterGoogleId";
import { types } from "../types";
import { startLogged, finishLogged, finishChecking } from "./ui";
// import { noteLogout } from './notes';

export const login = (user: IAuth) => ({
  type: types.login,
  payload: user,
});

export const logout = () => ({
  type: types.logout,
});

export const startLoginEmailPassword = (email: string, password: string) => {
  return async (dispatch: any) => {
    // dispatch(startLoading());

    Swal.fire({
      title: "Validando...",
      allowOutsideClick: false,
      // html: 'I will close in <b></b> milliseconds.',
      // timer: 2000,
      // timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let credentials: ICredentialsUser = { email, password };

    let token = await LoginCredentials(credentials);

    if (token) {
      localStorage.setItem("token", token);
      await dispatch(getUserAuth(token));
      // Swal.hideLoading();
      Swal.close();
    }

    // dispatch(finishLoading());
  };
};

// export const startRegisterWithEmailPasswordName = (email, password, name) => {
//   return (dispatch) => {
//     firebase
//       .auth()
//       .createUserWithEmailAndPassword(email, password)
//       .then(async ({ user }) => {
//         await user.updateProfile({ displayName: name });

//         dispatch(login(user.uid, user.displayName));
//       })
//       .catch((e) => {
//         console.log(e);
//         Swal.fire("Error", e.message, "error");
//       });
//   };
// };

export const startGoogleLogin = () => {
  return (dispatch: any) => {
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(async ({ user }) => {
        let userGoogle: IAuth = {
          fullName: user?.displayName,
          email: user?.email,
          photo: user?.photoURL,
          uid: "",
          role: "",
          token: "",
          google_id: user?.uid,
        };

        let newUser = await RegisterGoogleId(userGoogle);

        if (newUser) {
          localStorage.setItem("token", newUser.token);
          let {
            email_verified_at,
            role_id,
            created_at,
            updated_at,
            id,
            ...user
          } = newUser.user;
          let User = {
            ...user,
            uid: user.id,
            token: newUser.token,
          };
          dispatch(login(User));
          dispatch(startLogged());
        }
      });
  };
};

export const getUserAuth = (tokenCurrent: string) => async (dispatch: any) => {
  let getUser = await GetUserAuth(tokenCurrent);
  if (getUser) {
    let { email_verified_at, role_id, created_at, updated_at, id, ...user } =
      getUser;
    let User = {
      ...user,
      uid: user.id,
      token: tokenCurrent,
    };
    dispatch(login(User));
    dispatch(startLogged());
  } else {
    dispatch(finishLogged());
    dispatch(startLogout());
  }
  dispatch(finishChecking());
  // await firebase.auth().signOut();

  // dispatch(logout());
  // dispatch( noteLogout() );
};

export const startLogout = () => {
  return async (dispatch: any) => {
    await firebase.auth().signOut();

    localStorage.removeItem("token");
    dispatch(logout());
    dispatch(finishLogged());
    // dispatch( noteLogout() );
  };
};
