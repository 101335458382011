// import Swal from "sweetalert2";
import { API } from "../../api";

export const GetUserAuth = async (token: string) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };  
  return await API.POST(`/user`, {}, config).then((data: IData) => {
    if (data.ok) {
      // Swal.fire({
      //     position: 'center',
      //     icon: 'success',
      //     title: data.message,
      //     showConfirmButton: false,
      //     timer: 1500
      //   })
    return data.data.user;
  }
    // Swal.fire({
    //   position: 'center',
    //   icon: 'error',
    //   title: data.message,
    //   showConfirmButton: false,
    //   timer: 1500
    // })
    return null;
  });
};
