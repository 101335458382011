import { types } from "../types";

const initialState: IUi = {
  loading: false,
  msgError: "",
  isLoggedIn: false,
  checking: true,
};

export const uiReducer = (
  state: UiState = initialState,
  action: UiAction
): IUi => {
  switch (action.type) {
    case types.uiSetError:
      return {
        ...state,
        msgError: action.payload.msgError,
      };

    case types.uiRemoveError:
      return {
        ...state,
        msgError: "",
      };

    case types.uiStartLoading:
      return {
        ...state,
        isLoggedIn: true,
      };

    case types.uiFinishLoading:
      return {
        ...state,
        isLoggedIn: false,
      };

    case types.uiStartChecking:
      return {
        ...state,
        checking: true,
      };

    case types.uiFinishChecking:
      return {
        ...state,
        checking: false,
      };

    default:
      return state;
  }
};
