import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { PackageList } from "../../../package/components/list/PackageList";
import "./styles.scss";
import "../styles.scss";

export const PackageView = () => {
  

  return (
    <>
      <Grid container className="admin-container-header">
        <Grid item xs={6}>
          <h2>Paquetes</h2>
        </Grid>
        <Grid item xs={6} className="admin-container-header-buttons">
          <Link to={`/admin/paquetes/create`}>
            <Button variant="contained" color="primary">
              Nuevo paquete
            </Button>
          </Link>
        </Grid>
      </Grid>
      <hr />
      <div className="admin-container-body">
        <PackageList />
      </div>
    </>
  );
};
