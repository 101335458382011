import { Button, Grid } from "@material-ui/core";
import { HotelCreate } from "../../../../hotel/components/create/HotelCreate";
import "./styles.scss";

export const HotelAdminCreate = () => {

  return (
    <>
      <Grid container spacing={1} className="admin-container-header">
        <Grid item xs={12} sm={6}>
          <h2>Creando hotel</h2>
        </Grid>
        <Grid item xs={12} sm={6} className="admin-container-header-buttons">
          <Button
            type="submit"
            form="form-hotel"
            variant="contained"
            color="primary"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
      <hr />
      <div className="admin-container-body">
        <HotelCreate />
      </div>
    </>
  );
};
