import { Switch, Route, Redirect } from "react-router-dom";
import { Header } from "../components/ui/layouts/client/Header";
import { HomeView } from "../views/home/HomeView";
import { LoginView } from "../views/auth/login/LoginView";
import { RegisterView } from "../views/auth/register/RegisterView";

import { HotelView } from "../views/hotel/HotelView";
import { PackageView } from "../views/package/PackageCardView";
import { ActivityView } from "../views/activity/ActivityView";
import { Footer } from "../components/ui/layouts/client/Footer";
import { ContactView } from "../views/contact/ContactView";
import { ActivityShow } from "../views/activity/components/show/ActivityShow";
import { HotelShow } from "../views/hotel/components/show/HotelShow";
import { PackageShow } from "../views/package/components/show/PackageShow";

export const ClientRouter = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={HomeView} />
        <Route exact path="/login" component={LoginView} />
        <Route exact path="/register" component={RegisterView} />

        <Route exact path="/hoteles" component={HotelView} />
        <Route exact path="/hoteles/detalles/:slug" component={HotelShow} />

        <Route exact path="/paquetes" component={PackageView} />
        <Route exact path="/paquetes/detalles/:slug" component={PackageShow} />
        
        <Route exact path="/actividades" component={ActivityView} />
        <Route exact path="/actividades/detalles/:slug" component={ActivityShow} />

        <Route exact path="/contacto" component={ContactView} /> 
        <Redirect to="/" />
      </Switch>
      <Footer />
    </>
  );
};
