import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { startRegisterWithEmailPasswordName } from "../../../redux/actions/auth";
import { Button, Grid, TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import { Alert } from "@material-ui/lab";

export const RegisterView = () => {
  // const dispatch = useDispatch();

  const handleRegister = (e: any) => {
    // dispatch(startRegisterWithEmailPasswordName(email, password, name));
  };

  window.scrollTo(0, 0);

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h3 className="auth__title">Registrarse</h3>
        <Formik
          initialValues={{
            name: "",
            email: "",
            password: "",
            password2: "",
          }}
          validate={(values) => {
            const errors: any = {};
            // Email
            if (!values.email) errors.email = "Correo equerido";
            else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            )
              errors.email = "Correo invalido";
            // Password
            if (!values.name) errors.name = "Nombre Requerido";
            if (!values.password) errors.password = "Contraseña Requerida";
            if (!values.password2)
              errors.password2 = "Confirmar contraseña Requerido";
            // else if(values.password?.length < 8)
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleRegister(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form
              onSubmit={handleSubmit}
              className="animate__animated animate__fadeIn animate__faster"
            >
              {errors.name && (
                <div className="auth__alert-error">{errors.name}</div>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="contact-form-input"
                    id="name"
                    label="Nombre"
                    type="text"
                    variant="filled"
                    name="name"
                    autoComplete="off"
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name && touched.name && (
                    <Alert severity="error">{errors.name}</Alert>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="contact-form-input"
                    id="email"
                    label="Email"
                    type="email"
                    variant="filled"
                    name="email"
                    autoComplete="off"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors.email && touched.email && (
                    <Alert severity="error">{errors.email}</Alert>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="contact-form-input"
                    id="password"
                    label="Contraseña"
                    type="password"
                    variant="filled"
                    name="password"
                    autoComplete="off"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {errors.password && touched.password && (
                    <Alert severity="error">{errors.password}</Alert>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="contact-form-input"
                    id="password2"
                    label="Confirmar contraseña"
                    type="password"
                    variant="filled"
                    name="password2"
                    autoComplete="off"
                    value={values.password2}
                    onChange={handleChange}
                  />
                  {errors.password2 && touched.password2 && (
                    <Alert severity="error">{errors.password2}</Alert>
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="default"
                // disabled={loading}
              >
                Registrar
              </Button>

              <Link to="/login" className="link">
                ¿Ya estas registrado?
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
