
export const types = {

    login: '[Auth] Login',
    logout: '[Auth] Logout',
    

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',
    
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',
    
    uiStartLogged: '[UI] Start logged',
    uiFinishLogged: '[UI] Finish logged',
    
    uiStartChecking: '[UI] startChecking',
    uiFinishChecking: '[UI] finishChecking',

    // Activity
    activitiesLoad: '[Activities] Load activities',
    activityLoad: '[Activities] Load activity',

    // Activity
    packagesLoad: '[Activities] Load packages',
    packageLoad: '[Activities] Load package',

    // Hotel
    hotelsLoad: '[Hotels] Load hoteles',
    hotelLoad: '[Hotels] Load hotel',


    topicsLoad: '[Topic] Load topics',
    topicLoad: '[Topic] Load topic',
    
    hotelCategoriesLoad: '[HotelCategory] Load hotelCategories',
    hotelCategoryLoad: '[HotelCategory] Load hotelCategory',

    contactsLoad: '[Contact] Load contacts',
    contactLoad: '[Contact] Load contact',

}
