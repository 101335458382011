import { ActivityList } from "./components/list/ActivityList";
import "./styles.scss"

export const ActivityView = () => {
  return (
    <div className="activity-container">
      <h2>Actividades turísticas</h2>
      <ActivityList />
    </div>
  );
};
