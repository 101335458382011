import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ActivityList } from "../../../activity/components/list/ActivityList";
import "./styles.scss";
import "../styles.scss";

export const ActivityView = () => {
  

  return (
    <>
      <Grid container className="admin-container-header">
        <Grid item xs={6}>
          <h2>Actividades</h2>
        </Grid>
        <Grid item xs={6} className="admin-container-header-buttons">
          <Link to={`/admin/actividades/create`}>
            <Button variant="contained" color="primary">
              Nueva actividad
            </Button>
          </Link>
        </Grid>
      </Grid>
      <hr />
      <div className="admin-container-body">
        <ActivityList />
      </div>
    </>
  );
};
