// import Swal from "sweetalert2";
import { API } from "../../api";

export const GetPackageBySlug = async (slug: string) => {
  //   let config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  return await API.GET(`/package/${slug}`).then((data: IData) => {
    if (data.ok) {
      // Swal.fire({
      //     position: 'center',
      //     icon: 'success',
      //     title: data.message,
      //     showConfirmButton: false,
      //     timer: 1500
      //   })
      return data.data.length ? data.data[0] : [];
    }
    // Swal.fire({
    //   position: 'center',
    //   icon: 'error',
    //   title: data.message,
    //   showConfirmButton: false,
    //   timer: 1500
    // })
    return null;
  });
};
