import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../../../assets/images/logo.png";
import { startLogout } from "../../../../../redux/actions/auth";

export const NavBarAdminList = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(startLogout());
  };

  return (
    <div>
      <nav className="navbar-admin-container">
        <img src={logo} alt="" height="70" />
        <ul>
          <li>
            <Link className="navbar-admin-li" to="/admin">
              <span
                className={`header-btn-link ${
                  pathname === "/admin" ? "navbar-admin-active" : ""
                }`}
              >
                Inicio
              </span>
            </Link>
          </li>
          <li>
            <Link className="navbar-admin-li" to="/admin/hoteles">
              <span
                className={`header-btn-link ${
                  pathname.includes("/admin/hoteles")
                    ? "navbar-admin-active"
                    : ""
                }`}
              >
                Hotoles
              </span>
            </Link>
          </li>
          <li>
            <Link className="navbar-admin-li" to="/admin/paquetes">
              <span
                className={`header-btn-link ${
                  pathname.includes("/admin/paquetes")
                    ? "navbar-admin-active"
                    : ""
                }`}
              >
                Paquetes
              </span>
            </Link>
          </li>
          <li>
            <Link className="navbar-admin-li" to="/admin/actividades">
              <span
                className={`header-btn-link ${
                  pathname.includes("/admin/actividades")
                    ? "navbar-admin-active"
                    : ""
                }`}
              >
                Actividades
              </span>
            </Link>
          </li>
          <li>
            <Link className="navbar-admin-li" to="/admin/contactos">
              <span
                className={`header-btn-link ${
                  pathname.includes("/admin/contactos")
                    ? "navbar-admin-active"
                    : ""
                }`}
              >
                Contactanos
              </span>
            </Link>
          </li>
          <hr />
          <br />
          {/* <Link className="navbar-admin-li" to="/admin/contacto">
            <span
              className={`header-btn-link ${
                pathname.includes("/admin/contacto")
                  ? "navbar-admin-active"
                  : ""
              }`}
            >
              Cliente
            </span>
          </Link> */}
          <Link className="navbar-admin-li" to="/">
            <span
              className={`header-btn-link btn-logout`}
              onClick={handleLogout}
            >
              Salir
            </span>
          </Link>
        </ul>
      </nav>
    </div>
  );
};
