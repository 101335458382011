import { Grid } from "@material-ui/core";
import "./styles.scss";
import "../styles.scss";
import { ContactList } from "./components/list/ContactList";

export const ContactView = () => {
  return (
    <>
      <Grid container className="admin-container-header">
        <Grid item xs={6}>
          <h2>Contactos</h2>
        </Grid>
        {/* <Grid item xs={6} className="admin-container-header-buttons">
          <Link to={`/admin/hoteles/create`}>
            <Button variant="contained" color="primary">
              Nuevo hotel
            </Button>
          </Link>
        </Grid> */}
      </Grid>
      <hr />
      <div className="admin-container-body">
        <ContactList />
      </div>
    </>
  );
};
