import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Skeleton } from "@material-ui/lab";

import { startAllPackages } from "../../../../redux/actions/package";
import { PackageCard } from "../card/PackageCard";
import { RootState } from "../../../../redux/reducers/index";
import "./styles.scss";
import { PackageCardLoading } from "../card/PackageCardLoading";

export const PackageList = () => {
  const dispatch = useDispatch();
  const { packages } = useSelector((state: RootState) => state.package);
  const [loading, setLoading] = useState<boolean>(true);

  const loadPackages = useCallback(async () => {
    await dispatch(startAllPackages());
    setLoading(false);
    setScrollY();
  }, [dispatch, setLoading]);

  const setScrollY = () => {
    const porScrollY = localStorage.getItem("posScrollY");
    if (porScrollY) window.scrollTo(0, parseInt(porScrollY));
    localStorage.removeItem("posScrollY");
  };

  useEffect(() => {
    loadPackages();
  }, [loadPackages]);
  return (
    <Grid className="package-list" container spacing={4}>
      {loading
        ? [1, 2, 3, 4, 5, 6, 7, 8].map((load: number) => (
            <Grid key={load} item xs={12} sm={6} lg={3}>
              <PackageCardLoading />
            </Grid>
          ))
        : packages &&
          (packages.length ? (
            packages.map((packag: IPackage, i) => (
              <Grid key={packag.id} item xs={12} sm={6} lg={3}>
                <PackageCard {...packag} />
              </Grid>
            ))
          ) : (
            <h3>Sin paquetes</h3>
          ))}
    </Grid>
  );
};
