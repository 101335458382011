import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { URL_IMAGES } from "../../../../api";
import { Close } from "@material-ui/icons";

import "./styles.scss";

export const DragAndDropMini = (props) => {
  const classes = useStyles();
  let { name, images = [], action } = props;
  return (
    <div className="package__drag-drop--mini">
      <img
        className={classes.img}
        src={`${URL_IMAGES}${images[0]?.url}`}
        alt=""
        height="50"
        width="50"
      />
      <Tooltip title={name}>
        <p className={classes.name}>{name}</p>
      </Tooltip>
      <Button
        variant="outlined"
        color="secondary"
        className={classes.button}
        onClick={action}
      >
        <Close />
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  img: {
    objectFit: "cover",
  },
  button: {
    marginRight: 5,
  },
  name: {
    height: 50,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding: 4,
    boxSizing: "border-box",
  },
}));
