import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { startSubscribeEmail } from "../../../redux/actions/contact";
import { Alert } from "@material-ui/lab";
import { Form, Formik } from "formik";

export const HomeSubscribe = () => {
  const dispatch = useDispatch();

  return (
    <Grid container className="home-subscribe-container">
      <Grid item xs={12} lg={9} className="home-subscribe-background">
        <Grid container spacing={2} className="home-subscribe">
          <Grid item xs={12} lg={6}>
            <h3>Suscríbete</h3>
            <p>
              Suscríbase para recibir lo ultimo en paquetes e información. ¡No
              te preocupes, no enviaremos spam!
            </p>
          </Grid>
          <Grid item xs={12} lg={6} className="home-subscribe-form">
            <Formik
              initialValues={{
                email: "",
              }}
              validate={(values) => {
                let errors: any = {};
                if (!values.email) errors.email = "El correo es requerido";
                return errors;
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                await dispatch(startSubscribeEmail(values.email));
                resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit} id="form-activity">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="outlined-search"
                        label="Correo electronico"
                        type="email"
                        name="email"
                        variant="outlined"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <Alert severity="error">{errors.email}</Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button variant="contained" color="default" type="submit">
                        Subscribir me
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
