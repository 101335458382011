import { Grid } from '@material-ui/core';
import { HomeCardInfo } from './HomeCardInfo';

import logo1 from "../../../assets/icons/cama.png";
import logo2 from "../../../assets/icons/guia.png";
import logo3 from "../../../assets/icons/cola.png";
import logo4 from "../../../assets/icons/box.png";
import "./styles.scss";

export const HomeInformation = () => {
    return (
        <div className="hone-info-background">
            <Grid container className="home-information">
                <Grid item xs={12} md={5} className="home-info-left">
                    <h2>¿Qué estás buscando?</h2>
                    <p className="home-info-left-text">
                        Tenemos preparados diferentes actividades, para hacerte la aventura mucho más facil.
                        Cuentanos de que manera quieres pasar tus vacaciones y nosotros ayudamos a armar el mejor paquete que se adapte a tí.
                    </p>
                </Grid>
                <Grid item xs={12} md={7} >
                    <Grid container  spacing={2}>
                        <Grid item xs={12} md={6}>
                            <HomeCardInfo
                                logo={logo1}
                                title={"Posadas"}
                                text={"Alojamientos turisticos esperando por tu visita, para atenderte de la mejor manera, estos estan ubicados en distintas partes de la zona turistica de Nuquí, así podras elejir la que mejor se adpate a tus necesidades."}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <HomeCardInfo
                                logo={logo2}
                                title={"Guía turistica"}
                                text={"No estaras solo en esta aventura almenos que así lo decees, contaras con guias que te enseñaran los ricos paisas y culturas que brinda nuestra naturaleza."}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <HomeCardInfo
                                logo={logo3}
                                title={"Avistamientos de ballenas"}
                                text={"Temporadas especiales donde se pueden vivenciar las ballenas con sus ballenatos, ya que la encenada de Utría ubicada en el municipio de nuqui es catalogada como una cuna de las ballena."}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <HomeCardInfo
                                logo={logo4}
                                title={"Paquetes"}
                                text={"Con junto de actividades turisticas elaboradas para tí, revisa las distintas opciones que tenemos y disfrutalos. Tú elijes nosotros te lo armamos, desde caminatas en la selva asta pescas artesanal y mucho más."}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
