import { BoxLoading } from "react-loadingg";

import "./styles.scss"
import logo from "../../../assets/images/logo.png";

export const PreloadView = () => {
    return (
        <div className="preload">
            <img src={logo} alt="Logo Nuquitours" />
            <br/>
            <div className="preload-icon">
                <BoxLoading />
            </div>

        </div>
    )
}

export const PreloadIcon = () => {
    return (
        <div className="preload">
            <div className="preload-icon">
                <BoxLoading />
            </div>
        </div>
    )
}