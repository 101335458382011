import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Alert } from "@material-ui/lab";
import { pink, blue } from "@material-ui/core/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import { ActivityFormImageList } from "../../../activity/components/form/ActividyFormImageList";
import { FieldArray } from "formik";
import { ActivityFormPriceList } from "../../../activity/components/form/ActivityFormPriceList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { useCallback, useEffect } from "react";
import { startAllTopics } from "../../../../redux/actions/topic";

export const HotelFormActivityCard = ({
  activitiesDel,
  activity,
  errors,
  handleChageActivity,
  handleChageFileAndPrice,
  i,
}: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { topics } = useSelector((state: RootState) => state.topic);

  const loadTopic = useCallback(async () => {
    await dispatch(startAllTopics());
  }, [dispatch]);

  useEffect(() => {
    loadTopic();
  }, [loadTopic]);

  return (
    <Grid item xs={12}>
      <Card style={{ backgroundColor: blue[50] }}>
        <CardHeader
          subheader={`actividad ${i + 1}`}
          action={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tooltip title="Descartar actividad">
                  <Button
                    variant="contained"
                    onClick={() => activitiesDel(activity)}
                  >
                    <CancelIcon style={{ color: pink[500] }} />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.formControl}
                id="name"
                label="Nombre de la actividad *"
                type="text"
                name="name"
                autoComplete="off"
                value={activity.activity.name}
                onChange={({ target }) => handleChageActivity(target)}
              />
              {errors.name && <Alert severity="error">{errors.name}</Alert>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel id="demo-simple-select-filled-label">
                  Categoria de actividad *
                </InputLabel>
                <Select
                  name="topic_id"
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={activity.activity.topic_id || 0}
                  onChange={({ target }) => handleChageActivity(target)}
                >
                  <MenuItem value={0}>
                    <em>Seleccinar</em>
                  </MenuItem>
                  {topics.map((topic, index) => (
                    <MenuItem key={index} value={topic.id}>
                      {topic.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.topic_id && (
                <Alert severity="error">{errors.topic_id}</Alert>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.formControl}
                id="description"
                label="Descripción"
                type="text"
                name="description"
                value={activity.activity.description || ""}
                onChange={({ target }) => handleChageActivity(target)}
                multiline
                rows={6}
              />
              {errors.description && (
                <Alert severity="error">{errors.description}</Alert>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.formControl}
                id="review"
                label="Reseña"
                type="text"
                name="review"
                value={activity.activity.review || ""}
                onChange={({ target }) => handleChageActivity(target)}
                multiline
                rows={6}
              />
              {errors.review && <Alert severity="error">{errors.review}</Alert>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.formControl}
                id="include"
                label="Incluye"
                type="text"
                name="include"
                value={activity.activity.include || ""}
                onChange={({ target }) => handleChageActivity(target)}
                multiline
                rows={6}
              />
              {errors.include && (
                <Alert severity="error">{errors.include}</Alert>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.formControl}
                id="note"
                label="Nota"
                type="text"
                name="note"
                value={activity.activity.note || ""}
                onChange={({ target }) => handleChageActivity(target)}
                multiline
                rows={6}
              />
              {errors.note && <Alert severity="error">{errors.note}</Alert>}
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-filled-label">
                Precios *
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <FieldArray
                  name="prices"
                  render={(arrayHelpers) => (
                    <ActivityFormPriceList
                      priceDelete={(index: number) => {
                        activity.delPrices = [
                          ...activity.delPrices,
                          activity.activity.prices[index].id,
                        ];
                        activity.activity.prices.splice(index, 1);
                        handleChageFileAndPrice("prices", activity);
                      }}
                      pricesUpdate={(index: number, newPrices: any) =>
                        arrayHelpers.replace(index, newPrices)
                      }
                      prices={activity.activity.prices}
                      errors={errors}
                      type="price"
                    />
                  )}
                />
                <ActivityFormPriceList
                  pricesUpdate={(newPrices: any) =>
                    handleChageFileAndPrice({
                      name: "newPrices",
                      value: [...newPrices],
                    })
                  }
                  prices={activity.newPrices || []}
                  errors={errors}
                  type="newPrices"
                />
              </Grid>
              {errors.prices && <Alert severity="error">{errors.prices}</Alert>}
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-filled-label">
                Imagenes *
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <FieldArray
                  name="images"
                  render={(arrayHelpers) => (
                    <ActivityFormImageList
                      filesUpdate={(index: number) => {
                        activity.delImages = [
                          ...activity.delImages,
                          activity.activity.images[index].id,
                        ];
                        activity.activity.images.splice(index, 1);
                        handleChageFileAndPrice("images", activity);
                      }}
                      files={activity.activity.images}
                      type="image"
                    />
                  )}
                />
                <ActivityFormImageList
                  filesUpdate={(newFiles: any) =>
                    handleChageFileAndPrice({
                      name: "newImages",
                      value: [...newFiles],
                    })
                  }
                  files={activity.newImages || []}
                  type="newImages"
                  index={i}
                />
              </Grid>
              {errors.images && <Alert severity="error">{errors.images}</Alert>}
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-filled-label">
                Videos
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <FieldArray
                  name="videos"
                  render={(arrayHelpers) => (
                    <ActivityFormImageList
                      filesUpdate={(index: number) => {
                        activity.delVideos = [
                          ...activity.delVideos,
                          activity.activity.videos[index].id,
                        ];
                        activity.activity.videos.splice(index, 1);
                        handleChageFileAndPrice("videos", activity);
                      }}
                      files={activity.activity.videos}
                      type="video"
                    />
                  )}
                />
                <ActivityFormImageList
                  filesUpdate={(newFiles: any) =>
                    handleChageFileAndPrice({
                      name: "newVideos",
                      value: [...newFiles],
                    })
                  }
                  files={activity.newVideos || []}
                  type="newVideos"
                  index={i}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  })
);
