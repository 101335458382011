import { withRouter } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import "./styles.scss";
import { NavBarAdminList } from "./components/NavBarAdminList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      flex: "1 0 auto",
      margin: theme.spacing(1),
    },
  })
);

const NavBarCreate = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Hidden smDown>
          <NavBarAdminList />
          {/* <div></div> */}
        </Hidden>
      </div>
    </>
  );
};

export const NavBarAdmin = withRouter(NavBarCreate);
