import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBvIsLVH77zvLX7dP96OFx91aQ0kt0kJjw",
  authDomain: "nuquitours-68e36.firebaseapp.com",
  projectId: "nuquitours-68e36",
  storageBucket: "nuquitours-68e36.appspot.com",
  messagingSenderId: "991614876971",
  appId: "1:991614876971:web:afdde8119530c10182d96e",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { db, googleAuthProvider, firebase };
