import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { HotelCard } from "../card/HotelCard";
import "./styles.scss";
// import { HotelCardLoading } from "../card/HotelCardLoading";
import { startAllContacts } from "../../../../../../redux/actions/contact";
import { RootState } from "../../../../../../redux/reducers";
import ContactListTable from "../table/ContactListTable";
import ContactListSkeleton from "../../../../../../components/skeleton/contact/ContactListSkeleton";

export const ContactList = () => {
  const dispatch = useDispatch();
  const { contacts } = useSelector((state: RootState) => state.contact);
  const [loading, setLoading] = useState<boolean>(true);

  const loadContacts = useCallback(async () => {
    await dispatch(startAllContacts());
    setLoading(false);
    setScrollY();
  }, [dispatch, setLoading]);

  const setScrollY = () => {
    const porScrollY = localStorage.getItem("posScrollY");
    if (porScrollY) window.scrollTo(0, parseInt(porScrollY));
    localStorage.removeItem("posScrollY");
  };

  useEffect(() => {
    loadContacts();
  }, [loadContacts]);

  return (
    <Grid className="hotel-list" container spacing={4}>
      {loading ? <ContactListSkeleton /> : <ContactListTable data={contacts} />}
    </Grid>
  );
};
