import { Button, Grid, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { startCreateContact } from "../../../redux/actions/contact";
import { initialStateContact } from "../../../redux/reducers/contactReducer";
import "./styles.scss";

export const ContactForm = () => {
  const dispatch = useDispatch();

  return (
    <div className="contact-item-card conact-form">
      <h3>Formulario de contacto</h3>
      <br />
      <Formik
        initialValues={initialStateContact.contact}
        validate={(values) => {
          let errors: any = {};
          // Email
          if (!values.fullName) errors.fullName = "El nombre es requerido";
          if (!values.email) errors.email = "El correo es requerido";
          if (!values.message) errors.message = "El mensaje es requerido";
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // handleLogin(values);
          let res = await dispatch(startCreateContact(values));
          if (res.toString() === "true") resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit} id="form-activity">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className="contact-form-input"
                  id="filled-multiline-static"
                  label="Nombre *"
                  name="fullName"
                  rows={4}
                  value={values.fullName}
                  variant="filled"
                  onChange={handleChange}
                />
                {errors.fullName && touched.fullName && (
                  <Alert severity="error">{errors.fullName}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className="contact-form-input"
                  id="filled-multiline-static"
                  label="Correo *"
                  type="email"
                  rows={4}
                  name="email"
                  value={values.email}
                  variant="filled"
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <Alert severity="error">{errors.email}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className="contact-form-input"
                  id="filled-multiline-static"
                  label="Asunto"
                  rows={4}
                  name="subject"
                  value={values.subject}
                  variant="filled"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className="contact-form-input"
                  id="filled-multiline-static"
                  label="Celular"
                  type="number"
                  rows={4}
                  name="cellPhone"
                  value={values.cellPhone}
                  variant="filled"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="contact-form-input"
                  id="filled-multiline-static"
                  label="Escribre tu mensage *"
                  rows={5}
                  multiline
                  name="message"
                  value={values.message}
                  variant="filled"
                  onChange={handleChange}
                />
                {errors.message && touched.message && (
                  <Alert severity="error">{errors.message}</Alert>
                )}
              </Grid>
            </Grid>
            <br />
            <Button type="submit" variant="contained" color="primary">
              Envíar mensaje
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
