import Swal from "sweetalert2";
import { API } from "../../api";

export const LoginCredentials = async (credentials: ICredentialsUser) => {
  let config = {
    headers: {
      'Content-Type': 'application/json'
    },
  };
    
  return await API.POST(`/login`, credentials, config).then((data: IData) => {    
    if (data.ok) {
        // Swal.fire({
        //     position: 'center',
        //     icon: 'success',
        //     title: data.message,
        //     showConfirmButton: false,
        //     timer: 1500
        //   })
      return data.data.token;
    }
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: data.message,
      showConfirmButton: false,
      timer: 1500
    })
    return null;
  });
};