import { API } from "../../api";

export const DeleteActivity = async (id: number, token: string) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await API.DEL(`/activity/${id}`, config).then((data: IData) => data);
};
