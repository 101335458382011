import Swal from "sweetalert2";
import { getAllContact } from "../../helpers/contact/getAllContact";
import { getByIdContact } from "../../helpers/contact/getByIdContact";
import { postCreateContact } from "../../helpers/contact/postCreateContact";
import { postSubscribeEmail } from "../../helpers/contact/postSubscribeEmail";
import { types } from "../types";

export const contacts = (contacts: IContact[]) => ({
  type: types.contactsLoad,
  payload: { contacts },
});

export const contact = (contact: IContact) => ({
  type: types.contactLoad,
  payload: { contact },
});

export const startSubscribeEmail = (email: string) => {
  return async (dispatch: any) => {
    let res = await postSubscribeEmail(email);
    if (res.ok) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
      return null;
    }
  };
};

export const startAllContacts = () => {
  return async (dispatch: any) => {
    let allContacts = await getAllContact();
    await dispatch(contacts(allContacts));
  };
};

export const startContactById = (id: number) => {
  return async (dispatch: any) => {
    let getContact = await getByIdContact(id);
    if (getContact.length > 0) await dispatch(contact(getContact[0]));
  };
};

export const startCreateContact = (contact: IContact) => {
  return async (dispatch: any) => {
    Swal.fire({
      title: "Guardando..",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let res = null;

    res = await postCreateContact(contact);

    Swal.hideLoading();
    Swal.close();

    if (res.ok) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: res.message,
        showConfirmButton: false,
        timer: 1500,
      });
      return "false";
    }

    return "true";
  };
};

// export const startDeleteActivity = (id: number, token: string) => {
//   return async (dispatch: any) =>
//     await Swal.fire({
//       title: "¿Está seguro?",
//       text: "¡No podrás revertir esto!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Sí, eliminar actividad!",
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         Swal.fire({
//           title: "Eliminando..",
//           allowOutsideClick: false,
//           didOpen: () => {
//             Swal.showLoading();
//           },
//         });

//         let res = await DeleteActivity(id, token);

//         Swal.hideLoading();
//         Swal.close();

//         if (res.ok) {
//           Swal.fire({
//             position: "center",
//             icon: "success",
//             title: res.message,
//             showConfirmButton: false,
//             timer: 1500,
//           });
//         } else {
//           Swal.fire({
//             position: "center",
//             icon: "error",
//             title: res.message,
//             showConfirmButton: false,
//             timer: 1500,
//           });
//           return null;
//         }

//         return `/admin/actividades`;
//       }
//     });
// };
