import { types } from "../types";
// import { UserI } from "../../views/auth/login/LoginI";

export const initialStateActivity: ActivityState = {
  activities: [],
  activity: {
    id: 0,
    name: "",
    description: "",
    review: "",
    include: "",
    note: "",
    slug: "",
    topic_id: 0,
    state_id: 0,
    private: "no",
    images: [],
    videos: [],
    prices: [],
  },
};

export const activityReducer = (
  state: ActivityState = initialStateActivity,
  action: ActivityAction
): ActivityState => {
  switch (action.type) {
    case types.activitiesLoad:
      return {
        ...state,
        activities: action.payload.activities,
      };

    case types.activityLoad:
      return {
        ...state,
        activity: action.payload.activity,
      };

    default:
      return { ...state };
  }
};
