// import Swal from "sweetalert2";
import { GetTopicBySlug } from "../../helpers/topic/GetTopicBySlug";
import { GetAllTopics } from "../../helpers/topic/GetAllTopics";
import { types } from "../types";

export const topics = (topics: ITopic[]) => ({
  type: types.topicsLoad,
  payload: { topics },
});

export const topic = (topic: ITopic) => ({
  type: types.topicLoad,
  payload: { topic },
});

export const startAllTopics = () => {
  return async (dispatch: any) => {
    let allTopics = await GetAllTopics();
    await dispatch(topics(allTopics));
  };
};

export const startTopicBySlug = (slug: string) => {
  return async (dispatch: any) => {
    let getTopic = await GetTopicBySlug(slug);
    await dispatch(topic(getTopic));
  };
};
