import { Grid } from "@material-ui/core";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { startContactById } from "../../../../../../redux/actions/contact";
import { RootState } from "../../../../../../redux/reducers";

export const ContactShow = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const { contact } = useSelector((state: RootState) => state.contact);
//   const [loading, setLoading] = useState<boolean>(true);

  console.log(contact?.fullName);

  const loadContact = useCallback(async () => {
    await dispatch(startContactById(params.id));
    // setLoading(false);
    setScrollY();
  }, [dispatch, params]);

  const setScrollY = () => {
    const porScrollY = localStorage.getItem("posScrollY");
    if (porScrollY) window.scrollTo(0, parseInt(porScrollY));
    localStorage.removeItem("posScrollY");
  };

  useEffect(() => {
    loadContact();
  }, [loadContact]);

  return (
    <>
      <Grid container className="admin-container-header">
        <Grid item xs={6}>
          <h2>Contactos</h2>
        </Grid>
      </Grid>
      <hr />
      <div className="admin-container-body">
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <h3>{contact?.subject}</h3>
                <span>{contact?.created_at}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <strong>Mensaje</strong>
                <p>{contact?.message}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <strong>Enviado por</strong>
                <p>
                  Nombre: <strong>{contact?.fullName}</strong>{" "}
                </p>
                <p>
                  Celular: <strong>{contact?.cellPhone}</strong>
                </p>
                <p>
                  Correo: <strong>{contact?.email}</strong>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
