import { types } from "../types";
// import { UserI } from "../../views/auth/login/LoginI";

export const initialStatePackage: PackageState = {
  packages: [],
  package: {
    id: 0,
    name: "",
    description: "",
    include: "",
    note: "",
    slug: "",
    state_id: 0,
    private: "no",
    hotels: [],
    activities: [],
    images: [],
    videos: [],
    prices: [],
  },
};

export const packageReducer = (
  state: PackageState = initialStatePackage,
  action: PackageAction
): PackageState => {
  switch (action.type) {
    case types.packagesLoad:
      return {
        ...state,
        packages: action.payload.packages,
      };

    case types.packageLoad:
      return {
        ...state,
        package: action.payload.package,
      };

    default:
      return { ...state };
  }
};
