import { Grid } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Skeleton } from "@material-ui/lab";

import { startAllHotels } from "../../../../redux/actions/hotel";
import { HotelCard } from "../card/HotelCard";
import { RootState } from "../../../../redux/reducers/index";
import "./styles.scss";
import { HotelCardLoading } from "../card/HotelCardLoading";

export const HotelList = () => {
  const dispatch = useDispatch();
  const { hotels } = useSelector((state: RootState) => state.hotel);
  const [loading, setLoading] = useState<boolean>(true);

  const loadHotels = useCallback(async () => {
    await dispatch(startAllHotels());
    setLoading(false);
    setScrollY()
  }, [dispatch, setLoading]);

  const setScrollY = () => {
    const porScrollY = localStorage.getItem("posScrollY")
    if (porScrollY) window.scrollTo(0, parseInt(porScrollY))
    localStorage.removeItem("posScrollY")
  }

  useEffect(() => {
    loadHotels();
  }, [loadHotels]);
  return (
    <Grid className="hotel-list" container spacing={4}>
      {loading
        ? [1, 2, 3, 4, 5, 6, 7, 8].map((load: number) => (
            <Grid key={load} item xs={12} sm={6} lg={3}>
              <HotelCardLoading />
            </Grid>
          ))
        : hotels &&
          (hotels.length ? (
            hotels.map((hotel: IHotel, i) => (
              <Grid key={hotel.id} item xs={12} sm={6} lg={3}>
                <HotelCard {...hotel} />
              </Grid>
            ))
          ) : (
            <h3>Sin hoteles</h3>
        ))}
    </Grid>
  );
};
