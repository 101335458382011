import { Button, Grid } from "@material-ui/core";
import { PackageCreate } from "../../../../package/components/create/PackageCreate";
import "./styles.scss";

export const PackageAdminCreate = () => {

  return (
    <>
      <Grid container spacing={1} className="admin-container-header">
        <Grid item xs={12} sm={6}>
          <h2>Creando paquete</h2>
        </Grid>
        <Grid item xs={12} sm={6} className="admin-container-header-buttons">
          <Button
            type="submit"
            form="form-package"
            variant="contained"
            color="primary"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
      <hr />
      <div className="admin-container-body">
        <PackageCreate />
      </div>
    </>
  );
};
