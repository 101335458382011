import { Button, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Close } from "@material-ui/icons";
import { URL_IMAGES, URL_VIDEOS } from "../../../../api";

export const HotelFormImageCard = ({ fileDel, file, i, type }: any) => {
  return (
    <Grid item xs={6} sm={3}>
      <Card>
        <CardHeader
          subheader={`${i + 1}`}
          action={
            <Button onClick={() => fileDel(file)}>
              <Close />
            </Button>
          }
        />
        <CardContent>
          <figure
            style={{ height: 200, display: "flex", justifyContent: "center" }}
            // onClick={() => alert("ok")}
          >
            {type === "image" || type === "newImages" ? (
              <img
                id={`form-hotel-image-${i}`}
                src={
                  file.url.length > 100 ? file.url : `${URL_IMAGES}${file.url}`
                }
                height="100%"
                alt={file.url}
              />
            ) : (
              <video
                id={`form-hotel-video-${i}`}
                src={file.url.length > 100 ? file.url : `${URL_VIDEOS}${file.url}`}
                height="100%"
                controls
                muted
                loop
                autoPlay
              />
            )}
          </figure>
        </CardContent>
      </Card>
    </Grid>
  );
};
