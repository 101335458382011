import { Grid, Link } from "@material-ui/core";
import { Facebook, MapSharp, Email, Phone } from "@material-ui/icons";

import { HomeSubscribe } from "../home/components/HomeSubscribe";
import { ContactForm } from "./components/ContactForm";
import { ContactItemCard } from "./components/ContactItemCard";
import "./styles.scss";

export const ContactView = () => {
  return (
    <div className="contact-container">
      <Grid container>
        <Grid item xs={12}>
          <div className="contact-title">
            <h2>Contactanos</h2>
            <p>Estas a un paso de informarte sobre el viaje que deseas.</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ContactItemCard
            icon={<MapSharp />}
            title="Ubicación"
            message="Nuquí - Chocó - Colombia"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ContactItemCard
            icon={<Phone />}
            title="Telefono celular"
            message="+57 3185861747 - +57 3162946829"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ContactItemCard
            icon={<Email />}
            title="Correo electronico"
            message="info@nuquitours.com.co"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <ContactItemCard
            icon={
              <Link
                href="https://www.facebook.com/nuquitours/"
                target={"_blank"}
                color="inherit"
                variant="button"
              >
                <Facebook />
              </Link>
            }
            title="Facebook"
            message="Facebook Nuquí Tours"
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <ContactForm />
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="contact-card">
            <h4>Horario de operación</h4>
            <p>Escribenos a cualquier hora, nosotros te responderemos.</p>
            <strong>Siguenos</strong>
            <Link
              href="https://www.facebook.com/nuquitours/"
              target={"_blank"}
              color="inherit"
              variant="button"
            >
              <Facebook />
            </Link>
          </div>
        </Grid>
      </Grid>
      <HomeSubscribe />
    </div>
  );
};
